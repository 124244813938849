import { Row, Col } from "antd";

import { useState, useEffect } from "react";
import TransparentCard from "./TransparentCard";
import StepVisibleCol from "./StepVisibleCol";

export default function ShowCaseCardRow(options) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.innerHeight > window.innerWidth ? true : false);
    window.addEventListener("resize", handleResize);
    // Call handleResize right away so that isMobile is set correctly initially
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Row
      justify="center"
      style={{
        paddingTop: "6vh",
        paddingBottom: "5vh",
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
      key={options.title}
    >
      {options.children.map((option, index) => {
        return (
          <Col
            span={isMobile ? 16 : 8}
            style={{
              paddingLeft: "2%",
              paddingBottom: isMobile ? "3vh" : "6vh",
              paddingRight: "2%",
            }}
            key={index + option.title}
          >
            <StepVisibleCol index={index % 3}>
              <TransparentCard option={option} zoom={1} />
            </StepVisibleCol>
          </Col>
        );
      })}
    </Row>
  );
}
