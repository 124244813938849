import React from "react";
import { Form, Input, Button, Spin, Layout, theme, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import { gql, useQuery, useMutation } from "@apollo/client";
import MainHeader from "../Component/Header";
import withThemeSwitch from "../Component/withThemeSwitch";

const GET_USER = gql`
  query MyQuery($id: uuid!) {
    user_by_pk(id: $id) {
      name
      email
      phone
    }
  }
`;

const MODIFY_USER = gql`
  mutation MyMutation(
    $id: uuid!
    $email: String
    $name: String
    $phone: String
  ) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: { email: $email, name: $name, phone: $phone }
    ) {
      id
    }
  }
`;

const UserDetails = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { error, data, refetch } = useQuery(GET_USER, {
    variables: { id: id },
  });
  if (error) {
    console.log(error.message);
  }
  const [modifyUser] = useMutation(MODIFY_USER);

  async function handleSignOut() {
    try {
      await signOut();
      navigate("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const emailValidator = (_, value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      return Promise.reject(new Error("Please input a valid email address!"));
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    console.log("Success:", values);
    modifyUser({
      variables: {
        email: values.Email,
        name: values.Username,
        phone: values.Phone,
        id: id,
      },
    }).then((result) => {
      message.success("User details updated successfully!");
      refetch();
    });
  };

  return (
    <MainHeader select="">
      <Layout.Content style={{ backgroundColor: colorBgContainer }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5vh 35vh 0 30vh",
          }}
        >
          <h1 style={{ textAlign: "center", flexGrow: 1 }}>
            Modify Your Account Details
          </h1>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "5vh",
          }}
        >
          {data ? (
            <Form
              onFinish={onFinish}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              labelAlign="left"
              style={{ width: "30%", minWidth: "500px" }}
            >
              <Form.Item
                name="Email"
                label="Email"
                initialValue={data.user_by_pk.email}
                rules={[
                  {
                    required: false,
                  },
                  {
                    validator: emailValidator,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="Username"
                label="Username"
                initialValue={data.user_by_pk.name}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="Phone"
                label="Phone"
                initialValue={data.user_by_pk.phone}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="Password"
                label="Password"
                rules={[
                  {
                    required: false,
                    message: "Please input your Benchmark Password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                wrapperCol={{ span: 24 }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <Button
                  type="default"
                  onClick={() => handleSignOut()}
                  style={{ marginRight: 30 }}
                >
                  Sign Out
                </Button>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Spin />
          )}
        </div>
      </Layout.Content>
    </MainHeader>
  );
};

export default withThemeSwitch(UserDetails);
