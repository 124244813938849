export const portfolios = [
  {
    title: "Jubilee Bridge",
    information: "Construction Visualisation",
    image: "/Jubilee Bridge/Jubilee Bridge Button Image.png",
    link: "/home_portfolio/jubilee_bridge",
  },
  {
    title: "P2P Electrification",
    information: "Construction Visualisation",
    image: "/P2P Electrification/Button PNG.png",
    link: "/home_portfolio/p2p_electrification",
  },
  {
    title: "SH Loop Road Tender",
    information: "Construction Visualisation",
    image: "/Sh Loop Road Tender/Button Png.png",
    link: "/home_portfolio/sh_loop_road_tender",
  },
  {
    title: "50 Albert Street",
    information: "Construction Visualisation",
    image: "/st.albert/Button PNG.png",
    link: "/home_portfolio/st_albert_street",
  },
];

export const services = [
  {
    title: "Comprehensive Construction Methodology",
    information: "",
    image: "/Construction Methodology Examples/Header and Button PNG.png",
    link: "/home_service/construction_methodology",
  },
  {
    title: "Custom 3D Modelling & BIM",
    information: "",
    image: "/3D Modeling Examples/Button and Header PNG.png",
    link: "/home_service/3d_modeling",
  },
  {
    title: "Custom Site Simulations",
    information: "",
    image: "/Construction Simulation Examples/Button and Header PNG.png",
    link: "/home_service/site_simulations",
  },
];
