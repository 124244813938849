import { useParams } from "react-router-dom";
import {
  JubileeBridge,
  P2PElectrification,
  SHLoopRoadTender,
  StAlbertStreet,
  defaultoData,
} from "./StaticData/HomePortfolioData";
import HomeFooter from "../Component/HomeComponent/HomeFooter";

import { useState, useEffect, useRef } from "react";
import { Space, Col, Row, Image } from "antd";
import { useLocation } from "react-router-dom";
import HomeHeader from "../Component/HomeComponent/HomeHeader";
import { getUrl } from "aws-amplify/storage";
import { portfolios } from "./StaticData/HomeData";
import ReactPlayer from "react-player";
import {
  TitleContainer,
  PageContainer,
  FirstSectionContainer,
  DetailsContainer,
  SubPageTitle,
  SectionContainer,
} from "../Component/HomeComponent/GeneralComponents";
import TransparentCard from "../Component/HomeComponent/TransparentCard";
import SubPageDetails from "../Component/HomeComponent/SubPageDetails";
import ArrowCarousel from "../Component/HomeComponent/ArrowCarousel";

export default function HomePortfolio() {
  let { page } = useParams();
  const [ismobile, setIsMobile] = useState(0);
  const [key, setKey] = useState(0);
  const location = useLocation();
  const [videoUrl, setVideoUrl] = useState("");
  const [imgDisplay, setImgDisplay] = useState(true);
  const [data, setData] = useState(defaultoData);
  const fetchLimiter = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    setKey((prevKey) => prevKey + 1);
  }, [location]);
  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.innerHeight > window.innerWidth ? 1 : 0);
    window.addEventListener("resize", handleResize);
    // Call handleResize right away so that ismobile is set correctly initially
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    switch (page) {
      case "jubilee_bridge":
        setData(JubileeBridge);
        break;
      case "p2p_electrification":
        setData(P2PElectrification);
        break;
      case "sh_loop_road_tender":
        setData(SHLoopRoadTender);
        break;
      case "st_albert_street":
        setData(StAlbertStreet);
        break;
      default:
        break;
    }
  }, [page]);

  useEffect(() => {
    if (fetchLimiter.current) {
      return;
    }
    const fetchVideoUrl = async () => {
      const pictureUrl = await getUrl({
        path: data.problem.image,
        options: {
          validateObjectExistence: true,
        },
      });

      setVideoUrl(pictureUrl ? pictureUrl.url.href : "");
    };

    if (data.problem.image.includes("mp4")) {
      setImgDisplay(false);
      fetchVideoUrl();
    }
  }, [data]);

  return (
    <PageContainer key={key}>
      <div>
        <FirstSectionContainer ismobile={ismobile} bg={data.image}>
          <HomeHeader style={{ position: "relative", zindex: "2" }} />
          <TitleContainer ismobile={ismobile} style={{ marginTop: "-3vh" }}>
            {data.title}
          </TitleContainer>
          <DetailsContainer ismobile={ismobile} style={{ marginTop: "-5vh" }}>
            <Space
              direction="vertical"
              size="small"
              style={{
                width: ismobile ? "70vw" : "40vw",
                fontSize: "inherit",
              }}
            >
              <Row style={{ paddingBottom: "3vh", fontSize: "inherit" }}>
                <Col
                  span={24}
                  style={{
                    fontWeight: 900,
                    fontSize: "inherit",
                  }}
                >
                  Client:{" "}
                </Col>
                <Col span={24} style={{ fontSize: "inherit" }}>
                  {data.client}
                </Col>
              </Row>
              <Row style={{ paddingBottom: "3vh", fontSize: "inherit" }}>
                <Col
                  span={24}
                  style={{
                    fontWeight: 900,
                    fontSize: "inherit",
                  }}
                >
                  Location:{" "}
                </Col>
                <Col span={24} style={{ fontSize: "inherit" }}>
                  {data.location}
                </Col>
              </Row>

              <div
                style={{
                  fontWeight: 900,
                }}
              >
                Services:{" "}
              </div>
              {data.services.split("&").map((service, index) => {
                return (
                  <div
                    style={{
                      paddingLeft: "1vw",
                      paddingTop: ismobile ? "0" : "-0.5vh",
                    }}
                    key={index + service}
                  >
                    &bull; {service}
                  </div>
                );
              })}
            </Space>
          </DetailsContainer>
        </FirstSectionContainer>
        <Row style={{ paddingTop: "10vh" }}>
          <Col span={ismobile ? 24 : 12}>
            <SubPageTitle
              ismobile={ismobile}
              style={{
                paddingLeft: ismobile ? "0vw" : "5vw",
                fontWeight: 500,
                paddingTop: "0",
                textAlign: ismobile ? "center" : "left",
              }}
            >
              Problem
            </SubPageTitle>
            <SubPageDetails ismobile={ismobile}>
              {data.problem.content}
            </SubPageDetails>
          </Col>
          <Col span={ismobile ? 2 : 0} />
          <Col
            span={ismobile ? 20 : 11}
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: ismobile ? "5vh" : "0",
            }}
          >
            {imgDisplay ? (
              <div
                style={{
                  width: "auto",
                  height: "100%", // Use the image height here
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  alt="showcase"
                  src={data.problem.image}
                  style={{
                    borderRadius: 10,
                    height: "auto",
                    objectFit: "contain",
                    width: "100%",
                    maxHeight: "90vh",
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ReactPlayer
                  url={videoUrl}
                  playsinline
                  muted
                  controls
                  playing
                  height="auto"
                  width="100%"
                  loop
                />
              </div>
            )}
          </Col>
          <Col span={ismobile ? 2 : 0} />
        </Row>
        <div style={{ paddingTop: "15vh", paddingBottom: "20vh" }}>
          <SubPageTitle
            ismobile={ismobile}
            style={{
              paddingLeft: ismobile ? "0vw" : "5vw",
              fontWeight: 500,
              paddingTop: "0",
              textAlign: ismobile ? "center" : "left",
            }}
          >
            Solution
          </SubPageTitle>
          <SubPageDetails
            ismobile={ismobile}
            style={{ width: ismobile ? "80%" : "50%" }}
          >
            {data.solution}
          </SubPageDetails>
        </div>
        <Row style={{ paddingTop: "5vh" }}>
          <Col span={ismobile ? 24 : 12}>
            <SubPageTitle
              ismobile={ismobile}
              style={{
                paddingLeft: ismobile ? "0vw" : "5vw",
                fontWeight: 500,
                paddingTop: "0",
                textAlign: ismobile ? "center" : "left",
              }}
            >
              Outcomes
            </SubPageTitle>
            <SubPageDetails ismobile={ismobile}>
              {data.outcome.content}
            </SubPageDetails>
          </Col>
          <Col span={ismobile ? 2 : 0} />
          <Col span={ismobile ? 20 : 11}>
            <div
              style={{
                display: "block",
                height: "auto",
                paddingTop: ismobile ? "5vh" : "0",
              }}
            >
              <ArrowCarousel more={false}>
                {data.outcome.image.map((image, index) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: 10,
                      }}
                      key={index + image}
                    >
                      <Image
                        src={image}
                        alt="showcase"
                        style={{ width: "100%", borderRadius: 10 }}
                      />
                    </div>
                  );
                })}
              </ArrowCarousel>
            </div>
          </Col>
          <Col span={ismobile ? 2 : 0} />
        </Row>
        <SectionContainer>
          <SubPageTitle ismobile={ismobile} style={{ paddingTop: "15vh" }}>
            More of Our Work
          </SubPageTitle>
          <ArrowCarousel
            ismobile={ismobile}
            more={true}
            style={{
              width: ismobile ? "80vw" : "50vw",
              height: ismobile ? "50vh" : "80vh",
              margin: "auto",
              display: "flex",
              marginTop: ismobile ? 0 : "-10vh",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {portfolios.map((portfolio, index) => {
              if (portfolio.link.split("/").pop() !== page) {
                return (
                  <TransparentCard
                    option={portfolio}
                    key={index + portfolio.title}
                    zoom={0}
                  />
                );
              } else {
                return null;
              }
            })}
          </ArrowCarousel>
        </SectionContainer>
        <HomeFooter />
      </div>
    </PageContainer>
  );
}
