// withThemeSwitch.jsx
import React from "react";
import ThemeSwitch from "./ThemeSwitch";

const withThemeSwitch = (WrappedComponent) => {
  return (props) => (
    <ThemeSwitch>
      <WrappedComponent {...props} />
    </ThemeSwitch>
  );
};

export default withThemeSwitch;
