import MainHeader from "../Component/Header";
import Footer from "../Component/Footer";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  theme,
  Input,
  Popover,
  Select,
  Tag,
  Button,
  DatePicker,
  Form,
  Table,
  Space,
  Tooltip,
  Empty,
} from "antd";
import {
  ControlOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { getCurrentUser } from "aws-amplify/auth";
import { useGlobalContext } from "../Component/GlobalContext";
import withThemeSwitch from "../Component/withThemeSwitch";

var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
const { Search } = Input;
const { RangePicker } = DatePicker;

const selectStatus = [
  {
    value: "New Updated",
  },
  {
    value: "On Progress",
  },
  {
    value: "Finished",
  },
  {
    value: "Pending",
  },
  {
    value: "Paid",
  },
];
const colorMap = (value) => {
  switch (value) {
    case "New Updated":
      return "green";
    case "On Progress":
      return "cyan";
    case "Finished":
      return "red";
    case "Pending":
      return "orange";
    case "Paid":
      return "blue";
    case "Creating":
      return "purple";
    default:
      return "empty";
  }
};

const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={colorMap(value)}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginInlineEnd: 4,
      }}
    >
      {label}
    </Tag>
  );
};

function RequestList() {
  const [height, setHeight] = useState(window.innerHeight);
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);
  const [fetchData, setFetchData] = useState([]);
  const {
    RequestListData: data,
    fetchRequestList,
    RequestListLoading: loading,
    selectedProject,
  } = useContext(useGlobalContext);

  useEffect(() => {
    if (!selectedProject) {
      navigate("/project_list");
    }
  }, [selectedProject, navigate]);

  useEffect(() => {
    if (!data) return;
    console.log(data.get_request_list_by_user_id);
    const formattedRequests = data.get_request_list_by_user_id
      .filter((element) => element.project_id === selectedProject)
      .map((element) => ({
        key: element.id,
        title: element.title,
        status: [element.status],
        deadline: element.due_date
          ? new Date(element.due_date).toLocaleDateString("en-NZ")
          : "TBD",
        update: element.update_date
          ? new Date(element.update_date).toLocaleDateString("en-NZ")
          : "TBD",
        assigns:
          element.technician_name[0] !== null
            ? element.technician_name
            : ["TBD"],
        unreadMessageCount: element.user_unread_message_count,
        projectId: element.project_id,
      }));

    setFetchData(formattedRequests);
    setRequests(formattedRequests);
  }, [data, selectedProject]);

  useEffect(() => {
    async function loginVerification() {
      try {
        const { userId } = await getCurrentUser();
        fetchRequestList({ variables: { user_id: userId } });
      } catch (err) {
        console.log(err);
      }
    }
    loginVerification();
  }, [fetchRequestList]);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  //This function is used to handle search request, info?.source will return 'input'
  const onSearch = (value, _e, info) => {
    setRequests(
      fetchData.filter((request) => {
        return request.title.toLowerCase().includes(value.toLowerCase());
      })
    );
  };
  const onFilterFinish = (values) => {
    let searchResult = fetchData;
    if (values.status && values.status.length > 0) {
      searchResult = searchResult.filter((request) =>
        values.status.some((status) => request.status.includes(status))
      );
    }
    if (values.deadline) {
      searchResult = searchResult.filter((request) => {
        if (request.deadline) {
          return dayjs(request.deadline, "DD MM YYYY").isBetween(
            values.deadline[0],
            values.deadline[1]
          );
        } else {
          return false;
        }
      });
    }
    if (values.Updated) {
      searchResult = searchResult.filter((request) => {
        if (request.update) {
          return dayjs(request.update, "DD MM YYYY").isBetween(
            values.Updated[0],
            values.Updated[1]
          );
        } else {
          return false;
        }
      });
    }
    setRequests(searchResult);
  };
  const onFilterFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const filter = (
    <Form
      name="basic"
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 350,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFilterFinish}
      onFinishFailed={onFilterFinishFailed}
      autoComplete="off"
    >
      <Form.Item label="Status" name="status">
        <Select
          placeholder="Select status"
          mode="multiple"
          tagRender={tagRender}
          options={selectStatus}
        />
      </Form.Item>
      <Form.Item label="Deadline" name="deadline">
        <RangePicker />
      </Form.Item>
      <Form.Item label="Updated" name="Updated">
        <RangePicker />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button htmlType="submit">Apply Filiter</Button>
      </Form.Item>
    </Form>
  );
  const columns = [
    {
      title: "Request Title",
      dataIndex: "title",
      key: "title",
      render: (_, { title, unreadMessageCount }) => {
        return (
          <Space
            direction="horizontal"
            style={{ height: "100%", alignItems: "center" }}
          >
            <div
              style={{
                width: "100%",
                float: "left",
                height: "100%",
                alignItems: "center",
                display: "flex",
              }}
            >
              {title}
            </div>

            {unreadMessageCount !== 0 && (
              <div
                style={{
                  marginLeft: 35,
                  fontWeight: "bold",
                  display: "flex",
                  borderRadius: "50%",
                  backgroundColor: "red",
                  color: "white",
                  fontSize: unreadMessageCount > 99 ? 9 : 11,
                  width: 20,
                  height: 20,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {unreadMessageCount > 99 ? "99+" : unreadMessageCount}
              </div>
            )}
          </Space>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          {status.map((state, index) => {
            let color = "none";

            if (state === "New Updated") {
              color = "green";
            } else if (state === "On Progress") {
              color = "cyan";
            } else if (state === "Finished") {
              color = "red";
            } else if (state === "Pending") {
              color = "orange";
            } else if (state === "Paid") {
              color = "blue";
            } else if (state === "Creating") {
              color = "purple";
            }
            return (
              <p key={index}>
                <Tag color={color}>{state}</Tag>
              </p>
            );
          })}
        </>
      ),
    },
    {
      title: "Due date",
      dataIndex: "deadline",
      key: "deadline",
      render: (text) => (text === null ? <p>TBD</p> : <p>{text}</p>),
      sorter: (a, b) => {
        const dateA = dayjs(a.deadline, "DD MM YYYY");
        const dateB = dayjs(b.deadline, "DD MM YYYY");

        if (!dateA.isValid()) {
          return -1;
        }
        if (!dateB.isValid()) {
          return 1;
        }

        return dateA.unix() - dateB.unix();
      },
    },

    {
      title: "Updated date",
      dataIndex: "update",
      key: "update",
      render: (text) => (text === null ? <p>TBD</p> : <p>{text}</p>),
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        const dateA = dayjs(a.update, "DD MM YYYY");
        const dateB = dayjs(b.update, "DD MM YYYY");

        if (!dateA.isValid()) {
          return 1;
        }
        if (!dateB.isValid()) {
          return -1;
        }

        return dateA.unix() - dateB.unix();
      },
    },
    {
      title: "Assign To",
      key: "assigns",
      dataIndex: "assigns",
      render: (_, record) => {
        const { assigns } = record;
        return (
          <>
            {assigns[0] === "TBD" ? (
              <p>TBD</p>
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {assigns.map((assign, index) => (
                  <span key={index}>
                    {assign}
                    {index < assigns.length - 1 && ", "}
                  </span>
                ))}
              </div>
            )}
          </>
        );
      },
    },
  ];
  return (
    <MainHeader select="Request">
      <Layout.Content
        style={{
          padding: 24,
          marginLeft: 2,
          minHeight: 280,
          background: colorBgContainer,
          height: height - 135,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="text"
            style={{ float: "left", marginLeft: "1vw" }}
            icon={<ArrowLeftOutlined style={{ fontSize: 26 }} />}
            onClick={() => navigate("/project_list")}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: -100,
            }}
          >
            <Popover
              content={filter}
              placement="bottomLeft"
              trigger="click"
              arrow={false}
            >
              <ControlOutlined style={{ fontSize: 26, marginRight: 30 }} />
            </Popover>

            <Search
              placeholder="search request by title"
              onSearch={onSearch}
              style={{ width: 400 }}
            />
          </div>
          <Tooltip title="Create New Request" placement="left">
            <Button
              type="text"
              style={{ float: "right", marginRight: "1vw" }}
              icon={<PlusOutlined style={{ fontSize: 26 }} />}
              onClick={() => navigate("/request_creation")}
            />
          </Tooltip>
        </div>
        <Table
          style={{ paddingTop: 24 }}
          columns={columns}
          loading={loading}
          dataSource={requests}
          pagination={false}
          virtual
          scroll={{ y: height - 280 }}
          locale={{
            emptyText: (
              <Empty description="You don't have a request yet">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => navigate("/request_creation")}
                >
                  Create Project
                </Button>
              </Empty>
            ),
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                navigate("/request_details", {
                  state: { requestUid: record.key },
                });
              },
            };
          }}
        />
      </Layout.Content>

      <Footer />
    </MainHeader>
  );
}

export default withThemeSwitch(RequestList);
