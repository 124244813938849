import React, { createContext, useState } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import { remove } from "aws-amplify/storage";

export const useGlobalContext = createContext();

const GET_REQUEST_DETAILS = gql`
  query GetDetails($requestUid: uuid!) {
    request_by_pk(id: $requestUid) {
      id
      actual_price
      construction_methodology
      description
      due_date
      estimate_price
      methodology_building
      methodology_outputs
      model_development
      methodology_phase
      model_equipment
      model_phase
      model_type
      model_usage
      status
      title
      update_date
      work_streams
      download_link
      output_format
      required_software
      budget
    }
  }
`;
const GET_REQUEST_LIST = gql`
  query Query($user_id: uuid!) {
    get_request_list_by_user_id(args: { input_user_id: $user_id }) {
      update_date
      title
      technician_name
      status
      id
      due_date
      project_id
      technician_unread_message_count
      user_unread_message_count
      project_name
    }
  }
`;

export default function GlobalContext(props) {
  const [isListRefetching, setIsListRefetching] = useState(false);
  const [isDetailRefetching, setIsDetailRefetching] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [tempMessageFile, setTempMessageFile] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const [
    fetchRequestDetail,
    {
      loading: RequestDetailLoading,
      error,
      data: RequestDetailData,
      refetch: refetchRequestDetail,
    },
  ] = useLazyQuery(GET_REQUEST_DETAILS);

  if (error) {
    console.log(error.message);
  }

  const [
    fetchRequestList,
    {
      loading: RequestListLoading,
      error: RequestListError,
      data: RequestListData,
      refetch: refetchRequestList,
    },
  ] = useLazyQuery(GET_REQUEST_LIST);
  if (RequestListError) {
    console.log(RequestListError.message);
  }

  // Refetching
  const handleRefetchRequestDetail = async () => {
    await refetchRequestDetail();
    setIsDetailRefetching(false);
  };

  const handleRefetchRequestList = async () => {
    await refetchRequestList();
    setIsListRefetching(false);
  };
  // Delete temp message file
  const deleteMessageTempUpload = async () => {
    if (tempMessageFile) {
      try {
        console.log("Remove ", tempMessageFile);
        remove({
          path: tempMessageFile,
        });
      } catch (error) {
        console.log("Error ", error);
      }
      setTempMessageFile(null);
    }
  };

  return (
    <useGlobalContext.Provider
      value={{
        fetchRequestDetail,
        RequestDetailLoading: RequestDetailLoading || isDetailRefetching,
        RequestDetailData,
        handleRefetchRequestDetail,
        setIsDetailRefetching,
        fetchRequestList,
        RequestListLoading: RequestListLoading || isListRefetching,
        RequestListData,
        handleRefetchRequestList,
        setIsListRefetching,
        deleteMessageTempUpload,
        selectedProject,
        setSelectedProject,
        setTempMessageFile,
        userId,
        setUserId,
        userName,
        setUserName,
      }}
    >
      {props.children}
    </useGlobalContext.Provider>
  );
}
