import React, { useState, useEffect } from "react";
import {
  PageContainer,
  SubPageTitle,
} from "../Component/HomeComponent/GeneralComponents";
import HomeHeader from "../Component/HomeComponent/HomeHeader";
import { Row, Col, Card } from "antd";
import StepVisibleCol from "../Component/HomeComponent/StepVisibleCol";
import { members } from "./StaticData/MemberData";

const Member = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [member, setMember] = useState([]);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.innerHeight > window.innerWidth ? true : false);
    window.addEventListener("resize", handleResize);
    // Call handleResize right away so that isMobile is set correctly initially
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setMember(members);
  }, []);

  return (
    <PageContainer>
      <HomeHeader />
      <SubPageTitle>Meet Our Team</SubPageTitle>
      <Row
        justify="center"
        style={{
          paddingBottom: "5vh",
          paddingLeft: "5vw",
          paddingRight: "5vw",
        }}
      >
        {member.map((person, index) => {
          return (
            <Col
              span={isMobile ? 16 : 8}
              style={{
                paddingLeft: "2%",
                paddingBottom: isMobile ? "3vh" : "6vh",
                paddingRight: "2%",
              }}
              key={index + person.name}
            >
              <StepVisibleCol index={index % 3}>
                <Card cover={<img alt={index} src={person.image} />}>
                  <Card.Meta
                    title={person.name}
                    description={person.description}
                  />
                </Card>
              </StepVisibleCol>
            </Col>
          );
        })}
      </Row>
    </PageContainer>
  );
};

export default Member;
