import { useState, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";

const StepVisibleCol = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isVisible && entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isVisible]);

  return (
    <StyledFadeAnimation
      isVisible={isVisible}
      ref={ref}
      delay={0.25 * props.index}
    >
      {props.children}
    </StyledFadeAnimation>
  );
};

export default StepVisibleCol;

const fadeInFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const StyledFadeAnimation = styled.div`
  opacity: 0;
  ${(props) =>
    props.isVisible &&
    css`
      animation: ${fadeInFromBottom} 1s ease-out forwards;
      animation-delay: ${(props) => props.delay || 0}s;
    `}
`;
