import { Button, Space } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function TransparentCard(props) {
  const navigate = useNavigate();
  const option = props.option;

  return (
    <TransparentStyltedCard
      onClick={(e) => {
        navigate(option.link);
      }}
      zoom={props.zoom}
    >
      <div
        style={{
          background: "linear-gradient(to bottom right, #000000, #052141)",
          height: 150,
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
        }}
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
            paddingTop: "5%",
          }}
        >
          <div
            style={{
              position: "relative",
              float: "left",
              paddingLeft: "5%",
              color: "whtite",
              fontWeight: 700,
              fontSize: 24,
              zIndex: 3,
              overflow: "auto", // Add this line
              whiteSpace: "normal", // Add this line
            }}
          >
            {option.title}
          </div>
          <div
            style={{
              position: "relative",
              float: "left",
              paddingLeft: "5%",
              color: "white",
              fontWeight: 700,
              fontSize: 14,
              zIndex: 3,
            }}
          >
            {option.information}
          </div>
        </Space>
      </div>

      <img
        src={option.image}
        alt="showcase"
        style={{
          position: "relative",
          float: "right",
          width: "100%",
          zIndex: 1,
          marginTop: -130,
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10,
          filter: "brightness(80%)", // Add this line
        }}
      />
    </TransparentStyltedCard>
  );
}

const TransparentStyltedCard = styled(Button)`
  width: 100%;
  height: auto;
  background-color: transparent;
  border-color: transparent;
  color: white;
  padding: 0;
  border-radius: 10px;
  &:hover {
    transform: scale(${(props) => (props.zoom ? 1.1 : 0.9)});
    z-index: 2;
    box-shadow: 0 0 10px 5px #00dbf8;
    background-color: transparent !important;
    color: white !important;
  }
`;
