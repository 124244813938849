import styled, { keyframes, css } from "styled-components";
import { useState, useEffect, useRef } from "react";
import { Space, Row, Col } from "antd";
import {
  DetailsContainer,
  SectionContainer,
  SubPageTitle,
} from "./GeneralComponents";

import { createFromIconfontCN } from "@ant-design/icons";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_4531892_qm19xvlhz3n.js",
});

export default function AboutUs(props) {
  const [isIconDescriptionVisible, setIsIconDescriptionVisible] =
    useState(false);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const descriptionRef = useRef();
  const ref = useRef();
  const ismobile = props.ismobile;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIconDescriptionVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsDescriptionVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const currentRef = descriptionRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <SectionContainer
      id="about"
      style={{
        paddingTop: "5vh",
        height: ismobile ? "auto" : "100vh",
      }}
    >
      <SubPageTitle
        ismobile={ismobile}
        style={{
          paddingTop: ismobile ? "1.5vh" : "3vh",
          paddingBottom: 0,
          marginLeft: 0,
        }}
      >
        About Us
      </SubPageTitle>
      <Row>
        <Col span={ismobile ? 24 : 11}>
          <DetailsContainer
            ismobile={ismobile}
            style={{ paddingRight: ismobile ? "10vw" : "0vw" }}
          >
            <StyledDescription
              isVisible={isDescriptionVisible}
              ref={descriptionRef}
              delay={0}
            >
              At RFSONS Engineering, we specialize in transforming construction
              challenges into opportunities for innovation. With a keen focus on
              the construction sector, we harness the power of digital tools to
              visualize complex construction methodologies, ensuring every
              project benefits from our deep understanding of both the risks and
              potentials inherent in modern infrastructure development. Our
              expertise lies in creating visualizations and 3D models that not
              only simplify complex processes but also enhance safety and
              cost-effectiveness across the project lifecycle. Through our
              collaborative approach, we dive deep into the specifics of each
              project, developing tailored solutions that navigate and solve
              critical issues. Our commitment to adding value to every
              construction project is evident through our meticulous planning,
              methodology development, and the delivery of clear, actionable
              insights. By partnering with us, clients gain a steadfast ally
              dedicated to ensuring project success, demonstrating our belief
              that the right technology and innovative thinking can redefine the
              standards of construction.
            </StyledDescription>
          </DetailsContainer>
        </Col>
        <Col span={ismobile ? 24 : 13}>
          <DetailsContainer
            ismobile={ismobile}
            style={{
              justifyContent: "left",
              display: "flex",
              paddingRight: "5vw",
            }}
          >
            <div style={{ height: "100%" }}>
              <Space
                direction="horizontal"
                style={{
                  paddingBottom: "calc(100vh * 72 / 1080)",
                  width: "100%",
                }}
              >
                <StyledIconDescription
                  isVisible={isIconDescriptionVisible}
                  ref={ref}
                  delay={0}
                >
                  <StyledInonFont type="icon-requests" />
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "calc(100vh * 36 / 1080)",
                    }}
                  >
                    1. Raise Requests
                  </div>
                </StyledIconDescription>
              </Space>
              <Space
                direction="horizontal"
                style={{
                  paddingBottom: "calc(100vh * 72 / 1080)",
                  width: "100%",
                }}
              >
                <StyledIconDescription
                  isVisible={isIconDescriptionVisible}
                  delay={0.25}
                >
                  <StyledInonFont type="icon-gongchengshejitu" />
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "calc(100vh * 36 / 1080)",
                    }}
                  >
                    2. Upload Design
                  </div>
                </StyledIconDescription>
              </Space>

              <Space
                direction="horizontal"
                style={{
                  paddingBottom: "calc(100vh * 72 / 1080)",
                  width: "100%",
                }}
              >
                <StyledIconDescription
                  isVisible={isIconDescriptionVisible}
                  delay={0.5}
                >
                  <StyledInonFont type="icon-tumuhejianzhu" />
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "calc(100vh * 36 / 1080)",
                    }}
                  >
                    3. Build & Visualize
                  </div>
                </StyledIconDescription>
              </Space>
              <Space direction="horizontal" style={{ width: "100%" }}>
                <StyledIconDescription
                  isVisible={isIconDescriptionVisible}
                  delay={0.75}
                >
                  <StyledInonFont type="icon-keshihua" />
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "calc(100vh * 36 / 1080)",
                    }}
                  >
                    4. Empower Projects
                  </div>
                </StyledIconDescription>
              </Space>
            </div>
          </DetailsContainer>
        </Col>
      </Row>
    </SectionContainer>
  );
}
const fadeInFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;
const fadeInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;
const StyledInonFont = styled(IconFont)`
  font-size: calc(100vh * 72 / 1080);
  padding-right: calc(100vh * 72 / 1080);
`;
const StyledIconDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  ${(props) =>
    props.isVisible &&
    css`
      animation: ${fadeInFromRight} 1s ease-out forwards;
      animation-delay: ${(props) => props.delay || 0}s;
    `}
`;
const StyledDescription = styled.div`
  opacity: 0;
  ${(props) =>
    props.isVisible &&
    css`
      animation: ${fadeInFromLeft} 1s ease-out forwards;
      animation-delay: ${(props) => props.delay || 0}s;
    `}
`;
