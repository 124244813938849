import { Carousel } from "antd";
import styled from "styled-components";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export default function ArrowCarousel(props) {
  return (
    <StyledCarousel
      autoplay
      more={props.more}
      arrows={props.ismobile ? false : true}
      prevArrow={<LeftOutlined />}
      nextArrow={<RightOutlined />}
      style={props.style}
    >
      {props.children}
    </StyledCarousel>
  );
}

export const StyledCarousel = styled(Carousel)`
  .slick-slide {
    height: auto !important;
  }

  .slick-slide slick-active slick-current {
    height: auto !important;
    align-self: start !important;
  }
  &:hover,
  &:focus {
    cursor: grab;
  }
  .slick-dots li button {
    background: black;
    opacity: 0.5;
  }

  > .slick-dots li.slick-active button {
    background: #aaa;
  }

  .slick-prev,
  .slick-prev:focus {
    margin-left: ${(props) => (props.more ? "-10vw" : "-3vw")};
    font-size: 3em;
    left: 10px;
    z-index: 2;
    color: #aaa;
  }

  .slick-next,
  .slick-next:focus {
    margin-right: ${(props) => (props.more ? "-10vw" : "-2vw")};
    font-size: 3em;
    right: 10px;
    z-index: 2;
    color: #aaa;
  }

  .slick-prev:hover,
  .slick-next:hover {
    color: #00e1ff;
  }
`;
