import { Space, ConfigProvider, FloatButton } from "antd";
import { useThemeContext } from "../App";
import { MoonOutlined } from "@ant-design/icons";
import { useState } from "react";
import Draggable from "react-draggable";

export default function ThemeSwitch(props) {
  const [isDragging, setIsDragging] = useState(false);
  const { state, dispatch } = useThemeContext();
  const [buttonState, setButtonState] = useState("default");

  const handleClick = () => {
    if (!isDragging) {
      if (state.name === "light") {
        dispatch({ type: "darkMode" });
        setButtonState("primary");
      } else if (state.name === "dark") {
        dispatch({ type: "lightMode" });
        setButtonState("default");
      }
    }
    setIsDragging(false); // Reset the dragging state after handling click
  };

  return (
    <ConfigProvider theme={{ algorithm: state.theme }}>
      {props.children}
      <Space>
        <Draggable
          onDrag={() => setIsDragging(true)}
          onStop={handleClick}
          defaultPosition={{ x: 0, y: -100 }}
          bounds="body"
        >
          <FloatButton icon={<MoonOutlined />} type={buttonState} />
        </Draggable>
      </Space>
    </ConfigProvider>
  );
}
