import { Row, Col, Card, Image } from "antd";
import { useContext, useState } from "react";
import styled from "styled-components";
import { ButtonStateContext } from "../../Request/RequestCreationState/RequestCreationReducer";

const { Meta } = Card;

export default function RequestShowCaseButton(options) {
  const { buttonState, dispatch } = useContext(ButtonStateContext);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const category = options.children.category;
  return (
    <Row justify="center">
      {options.children.selectionContent.map((option) => {
        let selected = false;
        if (Array.isArray(buttonState[category])) {
          selected = buttonState[category].includes(option.confirmType);
        } else {
          selected = buttonState[category] === option.confirmType;
        }

        return (
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={8}
            xl={6}
            xxl={8}
            style={{
              paddingLeft: "2%",
              paddingBottom: "2.5%",
              paddingRight: "2%",
            }}
            key={option.confirmType}
          >
            {
              <AnimatedCard
                style={{ height: "100%" }}
                title={option.title}
                cover={
                  <Image
                    alt="showcase"
                    src={option.image}
                    preview={{
                      onVisibleChange: (visible, prevVisible) => {
                        setIsPreviewOpen(visible);
                      },
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  />
                }
                selected={selected}
                onClick={(e) => {
                  if (isPreviewOpen) {
                    return;
                  }
                  dispatch({
                    type: Array.isArray(buttonState[category])
                      ? "multiSelect"
                      : "singleSelect",
                    value: option.confirmType,
                    category: category,
                  });
                }}
              >
                <Meta description={option.information} />
              </AnimatedCard>
            }
          </Col>
        );
      })}
    </Row>
  );
}

const AnimatedCard = styled(Card)`
  position: relative;
  border-radius: 3%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    border 0.3s ease-in-out;
  transform: ${(props) => (props.selected ? "scale(1.05)" : "scale(1)")};
  z-index: ${(props) => (props.selected ? 2 : 1)};
  box-shadow: ${(props) =>
    props.selected ? "0 0 10px 5px rgba(45, 179, 197, 0.5)" : "none"};

  &:hover {
    transform: scale(1.1);
    z-index: 2;
    box-shadow: 0 0 10px 5px rgba(223, 51, 102, 0.5);
  }
  .ant-card-head-title {
    white-space: normal;
    word-wrap: break-word;
  }
`;
