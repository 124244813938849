import MainHeader from "../Component/Header";

import {
  Layout,
  theme,
  Form,
  Input,
  message,
  Button,
  Row,
  Col,
  Modal,
  Upload,
  Spin,
  DatePicker,
  InputNumber,
} from "antd";
import {
  ArrowLeftOutlined,
  InboxOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import React, { useState, useEffect, useRef, useContext } from "react";
import RequestShowCaseButton from "../Component/RequestComponent/RequestShowCaseButton";
import styled from "styled-components";
import { ButtonStateContext } from "./RequestCreationState/RequestCreationReducer";
import { useLocation } from "react-router-dom";
import { RequestCreactionButtonData } from "./RequestCreationState/RequestCreationButtonData";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../Component/GlobalContext";
import { uploadData, remove } from "aws-amplify/storage";
import withThemeSwitch from "../Component/withThemeSwitch";
import dayjs from "dayjs";

const { Dragger } = Upload;
const INSERT_REQUEST = gql`
  mutation insert_request_one($project_id: uuid!) {
    insert_request_one(
      object: {
        project_id: $project_id
        work_streams: "None"
        construction_methodology: []
        methodology_building: []
        methodology_phase: "None"
        methodology_outputs: []
        model_development: []
        model_phase: "None"
        model_equipment: []
        model_type: []
        model_usage: []
      }
    ) {
      id
    }
  }
`;
const MODIFY_REQUEST = gql`
  mutation MyMutation(
    $requestUid: uuid!
    $requestName: String
    $requestDescription: String!
    $outputFormat: String
    $requiredSoftware: String
    $dueDate: timestamptz
    $budget: money
    $workStreams: String!
    $constructionMethodology: [String!]
    $methodologyBuilding: [String!]
    $methodologyPhase: String!
    $methodologyOutputs: [String!]
    $modelDevelopment: [String!]
    $modelPhase: String!
    $modelType: [String!]
    $modelUsage: [String!]
    $modelEquipment: [String!]
  ) {
    update_request_by_pk(
      pk_columns: { id: $requestUid }
      _set: {
        title: $requestName
        description: $requestDescription
        work_streams: $workStreams
        construction_methodology: $constructionMethodology
        methodology_building: $methodologyBuilding
        methodology_phase: $methodologyPhase
        methodology_outputs: $methodologyOutputs
        model_development: $modelDevelopment
        model_phase: $modelPhase
        model_type: $modelType
        model_usage: $modelUsage
        model_equipment: $modelEquipment
        budget: $budget
        due_date: $dueDate
        output_format: $outputFormat
        required_software: $requiredSoftware
      }
    ) {
      id
    }
  }
`;
const SUBMIT_REQUEST = gql`
  mutation MyMutation(
    $requestUid: uuid!
    $requestName: String
    $requestDescription: String!
    $outputFormat: String
    $requiredSoftware: String
    $dueDate: timestamptz
    $budget: money
    $workStreams: String!
    $constructionMethodology: [String!]
    $methodologyBuilding: [String!]
    $methodologyPhase: String!
    $methodologyOutputs: [String!]
    $modelDevelopment: [String!]
    $modelPhase: String!
    $modelType: [String!]
    $modelUsage: [String!]
    $modelEquipment: [String!]
    $status: String!
  ) {
    update_request_by_pk(
      pk_columns: { id: $requestUid }
      _set: {
        title: $requestName
        description: $requestDescription
        status: $status
        work_streams: $workStreams
        construction_methodology: $constructionMethodology
        methodology_building: $methodologyBuilding
        methodology_phase: $methodologyPhase
        methodology_outputs: $methodologyOutputs
        model_development: $modelDevelopment
        model_phase: $modelPhase
        model_type: $modelType
        model_usage: $modelUsage
        model_equipment: $modelEquipment
        budget: $budget
        due_date: $dueDate
        output_format: $outputFormat
        required_software: $requiredSoftware
      }
    ) {
      id
    }
  }
`;

const GET_FILE_LIST = gql`
  query MyQuery($requestUid: uuid!) {
    request_file(where: { request_id: { _eq: $requestUid } }) {
      description
      name
      storage_path
      type
      uid
    }
  }
`;
const INSERT_FILE = gql`
  mutation MyMutation(
    $name: String!
    $storage_path: String!
    $type: String!
    $uid: String!
    $request_id: uuid!
  ) {
    insert_request_file_one(
      object: {
        name: $name
        storage_path: $storage_path
        type: $type
        uid: $uid
        request_id: $request_id
      }
    ) {
      storage_path
    }
  }
`;
const MODIFY_FILE = gql`
  mutation MyMutation(
    $storage_path: String!
    $description: String!
    $request_id: uuid!
  ) {
    update_request_file_by_pk(
      pk_columns: { storage_path: $storage_path }
      _set: { description: $description, request_id: $request_id }
    ) {
      storage_path
    }
  }
`;
const DELETE_REQUEST = gql`
  mutation MyMutation($requestUid: uuid!) {
    delete_request_by_pk(id: $requestUid) {
      id
    }
  }
`;
const DELETE_FILE = gql`
  mutation MyMutation($storage_path: String!) {
    delete_request_file_by_pk(storage_path: $storage_path) {
      storage_path
    }
  }
`;

function ScrollToElement(navigation) {
  setTimeout(() => {
    const Element = document.getElementById(navigation);
    if (Element) {
      Element.scrollIntoView({ behavior: "smooth" });
    }
  }, 200);
}

function classNameDepender(item, buttonState) {
  switch (item) {
    case "constructionMethodology":
      return buttonState.workStreams === "constructionMethodology"
        ? "show"
        : "";
    case "methodologyBuilding":
      return buttonState.constructionMethodology.length > 0 &&
        buttonState.workStreams === "constructionMethodology"
        ? "show"
        : "";
    case "methodologyPhase":
      return buttonState.methodologyBuilding.length > 0 &&
        buttonState.constructionMethodology.length > 0 &&
        buttonState.workStreams === "constructionMethodology"
        ? "show"
        : "";
    case "methodologyOutputs":
      return buttonState.methodologyPhase !== "None" &&
        buttonState.methodologyBuilding.length > 0 &&
        buttonState.constructionMethodology.length > 0 &&
        buttonState.workStreams === "constructionMethodology"
        ? "show"
        : "";
    case "modelDevelopment":
      return buttonState.workStreams === "modelDevelopment" ? "show" : "";
    case "modelPhase":
      return (buttonState.modelDevelopment.includes("modelTemporaryWorks") ||
        buttonState.modelDevelopment.includes("modelPermanentWorks")) &&
        buttonState.workStreams === "modelDevelopment"
        ? "show"
        : "";
    case "modelEquipment":
      return buttonState.modelDevelopment.includes("modelEquipment") &&
        buttonState.workStreams === "modelDevelopment"
        ? "show"
        : "";
    case "modelType":
      return (buttonState.modelDevelopment.includes("modelTemporaryWorks") ||
        buttonState.modelDevelopment.includes("modelPermanentWorks")) &&
        buttonState.workStreams === "modelDevelopment" &&
        buttonState.modelPhase !== "None"
        ? "show"
        : "";
    case "modelUsage":
      return ((buttonState.modelDevelopment.includes("modelTemporaryWorks") ||
        buttonState.modelDevelopment.includes("modelPermanentWorks")) &&
        buttonState.workStreams === "modelDevelopment" &&
        buttonState.modelPhase !== "None" &&
        buttonState.modelType.length > 0) ||
        (buttonState.modelEquipment.length > 0 &&
          buttonState.workStreams === "modelDevelopment" &&
          buttonState.modelDevelopment.includes("modelEquipment"))
        ? "show"
        : "";
    case "uploadFiles":
      return (buttonState.methodologyOutputs.length > 0 &&
        buttonState.methodologyPhase !== "None" &&
        buttonState.methodologyBuilding.length > 0 &&
        buttonState.constructionMethodology.length > 0 &&
        buttonState.workStreams === "constructionMethodology") ||
        ((buttonState.modelDevelopment.includes("modelTemporaryWorks") ||
          buttonState.modelDevelopment.includes("modelPermanentWorks")) &&
          buttonState.workStreams === "modelDevelopment" &&
          buttonState.modelPhase !== "None" &&
          buttonState.modelType.length > 0 &&
          buttonState.modelUsage.length > 0) ||
        (buttonState.modelEquipment.length > 0 &&
          buttonState.workStreams === "modelDevelopment" &&
          buttonState.modelDevelopment.includes("modelEquipment") &&
          buttonState.modelUsage.length > 0)
        ? "show"
        : "";

    default:
      return "";
  }
}

function RequestCreation() {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [isDragOver, setIsDragOver] = useState(false);
  const [warning, setWarning] = useState(false);
  const [createdRequestUID, setCreatedRequestUID] = useState(null);
  const { buttonState, dispatch } = useContext(ButtonStateContext);
  const contentRef = useRef();
  const dragCounter = useRef(0);
  const buttonStateRef = useRef(buttonState);
  const location = useLocation();
  const renderCount = useRef(0);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fetchFileList] = useLazyQuery(GET_FILE_LIST);
  const [fileListData, setFileListData] = useState(null);
  const {
    handleRefetchRequestDetail: refetchRequestDetail,
    handleRefetchRequestList: refetchRequestList,
    setIsDetailRefetching,
    setIsListRefetching,
    RequestDetailLoading,
    RequestListLoading,
    RequestDetailData,
    selectedProject,
    userId,
  } = useContext(useGlobalContext);

  const [addRequest] = useMutation(INSERT_REQUEST);
  const [modifyRequest, { data: fetchUID }] = useMutation(MODIFY_REQUEST);
  const [submitRequest, { data: submitUID }] = useMutation(SUBMIT_REQUEST);
  const [addFiles] = useMutation(INSERT_FILE);
  const [modifyFiles] = useMutation(MODIFY_FILE);
  const [deleteFile] = useMutation(DELETE_FILE);
  const [removeRequest] = useMutation(DELETE_REQUEST);

  function modifyRequestWithoutStatus(requestUid) {
    try {
      const date = buttonState.dueDate
        ? dayjs(buttonState.dueDate).toISOString()
        : null;
      modifyRequest({
        variables: {
          requestUid: requestUid,
          requestName: buttonState.requestName
            ? buttonState.requestName
            : "New Request",
          dueDate: date,
          budget: buttonState.budget,
          outputFormat: buttonState.outputFormat,
          requiredSoftware: buttonState.requiredSoftware,
          requestDescription: buttonState.requestDescription,
          workStreams: buttonState.workStreams,
          constructionMethodology: buttonState.constructionMethodology,
          methodologyBuilding: buttonState.methodologyBuilding,
          methodologyPhase: buttonState.methodologyPhase,
          methodologyOutputs: buttonState.methodologyOutputs,
          modelDevelopment: buttonState.modelDevelopment,
          modelPhase: buttonState.modelPhase,
          modelType: buttonState.modelType,
          modelUsage: buttonState.modelUsage,
          modelEquipment: buttonState.modelEquipment,
        },
      });
    } catch (error) {
      message.error(
        "Failed to submit the request, please try again later. Error: " +
          error.message
      );
    }
  }
  function modifyRequestFileList(requestUid) {
    if (fileListData) {
      buttonState.fileDescription.forEach((file) => {
        const existsInRequestFile = fileListData.request_file.some(
          (requestFile) => requestFile.storage_path === file.storage_path
        );
        if (!existsInRequestFile) {
          modifyFiles({
            variables: {
              storage_path: file.path,
              description: file.description ? file.description : "",
              request_id: requestUid,
            },
          });
        }
      });

      // Update files record in database
      for (const file of buttonState.fileDescription) {
        const requestFile = fileListData.request_file.find(
          (requestFile) => requestFile.storage_path === file.storage_path
        );

        if (requestFile && file.description !== "") {
          modifyFiles({
            variables: {
              storage_path: file.storage_path,
              description: file.description,
              request_id: requestUid,
            },
          });
        }
      }
    } else {
      buttonState.fileDescription.forEach((file) => {
        modifyFiles({
          variables: {
            storage_path: file.path,
            description: file.description,
            request_id: requestUid,
          },
        });
      });
    }
  }

  function modifyTempRequest() {
    const requestUid = location.state
      ? location.state.requestDetails.id
      : createdRequestUID;
    setIsDetailRefetching(true);
    setIsListRefetching(true);
    modifyRequestWithoutStatus(requestUid);
    modifyRequestFileList(requestUid);
    //Record new files uploaded to database
  }

  function deleteTempRequest() {
    if (createdRequestUID) {
      buttonState.fileDescription.forEach((file) => {
        deleteFile({
          variables: {
            storage_path: file.path,
          },
        });
      });
      removeRequest({
        variables: {
          requestUid: createdRequestUID,
        },
      }).then(() => {
        setIsListRefetching(true);
        refetchRequestList({ id: userId });
      });
    }
  }

  const handleModalOk = () => {
    console.log("OK");
    navigate("/request_list");
  };

  const disabledPastDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  useEffect(() => {
    if (isModalVisible) {
      if (!RequestListLoading && !RequestDetailLoading) {
        setShowModal(true);
      }
    }
  }, [isModalVisible, RequestListLoading, RequestDetailLoading]);

  // After the request's modify has been submitted, refetch the new information of this request.

  useEffect(() => {
    if (fetchUID) {
      if (!createdRequestUID) {
        refetchRequestDetail({ id: fetchUID.update_request_by_pk.id });
      }
      setIsDetailRefetching(false);
      refetchRequestList({ id: userId });
    }
  }, [
    fetchUID,
    refetchRequestDetail,
    refetchRequestList,
    createdRequestUID,
    setIsDetailRefetching,
    userId,
  ]);

  useEffect(() => {
    if (submitUID) {
      if (!createdRequestUID) {
        refetchRequestDetail({ id: submitUID.update_request_by_pk.id });
      }
      setIsDetailRefetching(false);
      refetchRequestList({ id: userId });
    }
  }, [
    submitUID,
    refetchRequestDetail,
    refetchRequestList,
    createdRequestUID,
    setIsDetailRefetching,
    userId,
  ]);

  // used to get the request details if user want to modify their request
  useEffect(() => {
    renderCount.current += 1;
    if (location.state && renderCount.current === 1) {
      const item = location.state.requestDetails;
      Object.keys(item).forEach((key) => {
        if (key === "requestName") {
          dispatch({ type: "modifyRequestName", value: item[key] });
        } else if (key === "requestDescription") {
          dispatch({ type: "modifyRequestDescription", value: item[key] });
        } else if (key === "outputFormat") {
          dispatch({ type: "modifyOutputFormat", value: item[key] });
        } else if (key === "requiredSoftware") {
          dispatch({ type: "modifyRequiredSoftware", value: item[key] });
        } else if (key === "dueDate") {
          const date =
            item[key] === "-" ? null : dayjs(item[key], "DD/MM/YYYY");

          dispatch({ type: "modifyDueDate", value: date });
        } else if (key === "Budget") {
          dispatch({ type: "modifyBudget", value: item[key] });
        } else {
          if (Array.isArray(item[key])) {
            item[key].forEach((value) => {
              if (value) {
                dispatch({
                  type: "multiSelect",
                  value: value,
                  category: key,
                });
              }
            });
          } else {
            dispatch({
              type: "singleSelect",
              value: item[key],
              category: key,
            });
          }
        }
      });
    }
  });

  //Used to fetch the file list of the request that user already uploaded
  useEffect(() => {
    async function fetchFileData() {
      await fetchFileList({
        variables: { requestUid: location.state.requestDetails.id },
        fetchPolicy: "no-cache",
      })
        .then((response) => {
          setFileListData(response.data);
          response.data.request_file.forEach((file) => {
            dispatch({ type: "addFile", value: file });
          });
        })
        .catch((error) =>
          console.log(
            "Failed to fetch the file list, please try again later. Error: " +
              error
          )
        );
    }
    if (location.state) {
      fetchFileData();
    }
  }, [fetchFileList, location.state, dispatch]);

  //Used to set the information from form to Reducer for state organization
  useEffect(() => {
    form.setFieldsValue({
      projectName: buttonState.requestName,
      description: buttonState.requestDescription,
      outputFormat: buttonState.outputFormat,
      requiredSoftware: buttonState.requiredSoftware,
      dueDate: buttonState.dueDate,
      budget: buttonState.budget,
    });
  }, [
    buttonState.requestName,
    buttonState.requestDescription,
    buttonState.outputFormat,
    buttonState.requiredSoftware,
    buttonState.dueDate,
    buttonState.budget,
    form,
  ]);

  //Used to check if the user has changed the state of the request, if so, set the warning to true
  useEffect(() => {
    if (buttonState !== buttonStateRef.current) {
      if (warning === false) {
        setWarning(true);
      }
    } else {
      setWarning(false);
    }
  }, [buttonState, warning]);

  //Used for creating the unupload request
  useEffect(() => {
    if (!location.state) {
      if (warning === true) {
        addRequest({
          variables: {
            project_id: selectedProject,
          },
        })
          .then((response) => {
            const requestUid = response.data.insert_request_one.id;
            setCreatedRequestUID(requestUid);
          })
          .catch((error) => {
            console.log("Error ", error);
          });
      }
    }
  }, [warning, addRequest, location.state, selectedProject]);

  //Used to scroll to the correct section when the user click the button
  useEffect(() => {
    if (buttonState.workStreams === "constructionMethodology") {
      ScrollToElement("constructionMethodology");
    } else if (buttonState.workStreams === "modelDevelopment") {
      ScrollToElement("modelDevelopment");
    } else {
    }
  }, [buttonState.workStreams]);

  //Used to scroll to the correct section when the user click the button
  const prevConstructionMethodologyRef = useRef();
  useEffect(() => {
    if (
      buttonState.constructionMethodology.length > 0 &&
      prevConstructionMethodologyRef.current !== 1
    ) {
      prevConstructionMethodologyRef.current = 1;

      ScrollToElement("methodologyBuilding");
    } else if (buttonState.constructionMethodology.length === 0) {
      prevConstructionMethodologyRef.current = 0;
    }
  }, [buttonState.constructionMethodology]);

  //Used to scroll to the correct section when the user click the button
  const prevModelDevelopmentRef = useRef();
  useEffect(() => {
    if (
      buttonState.methodologyBuilding.length > 0 &&
      prevModelDevelopmentRef.current !== 1
    ) {
      prevModelDevelopmentRef.current = 1;
      ScrollToElement("methodologyPhase");
    } else if (buttonState.methodologyBuilding.length === 0) {
      prevModelDevelopmentRef.current = 0;
    }
  }, [buttonState.methodologyBuilding]);

  const prevmethodologyPhaseRef = useRef();

  //Used to scroll to the correct section when the user click the button
  useEffect(() => {
    if (
      buttonState.methodologyPhase !== "None" &&
      prevmethodologyPhaseRef.current !== 1
    ) {
      prevmethodologyPhaseRef.current = 1;
      ScrollToElement("methodologyOutputs");
    } else if (buttonState.methodologyPhase === "None") {
      prevmethodologyPhaseRef.current = 0;
    }
  }, [buttonState.methodologyPhase]);

  //Used to scroll to the correct section when the user click the button
  const prevModelOutputsRef = useRef();
  useEffect(() => {
    if (
      prevModelOutputsRef.current !== 1 &&
      (buttonState.modelDevelopment.includes("modelTemporaryWorks") ||
        buttonState.modelDevelopment.includes("modelPermanentWorks"))
    ) {
      prevModelOutputsRef.current = 1;
      ScrollToElement("modelPhase");
    } else if (
      prevModelOutputsRef.current !== 2 &&
      buttonState.modelDevelopment.includes("modelEquipment")
    ) {
      prevModelOutputsRef.current = 2;
      ScrollToElement("modelEquipment");
    } else if (buttonState.modelDevelopment.length === 0) {
      prevModelOutputsRef.current = 0;
    }
  }, [buttonState.modelDevelopment]);

  //Used to scroll to the correct section when the user click the button
  const prevModelPhaseRef = useRef();
  useEffect(() => {
    if (buttonState.modelPhase !== "None" && prevModelPhaseRef.current !== 1) {
      prevModelPhaseRef.current = 1;
      ScrollToElement("modelType");
    } else if (buttonState.modelPhase === "None") {
      prevModelPhaseRef.current = 0;
    }
  }, [buttonState.modelPhase]);

  //Used to scroll to the correct section when the user click the button
  const prevModelTypeRef = useRef();
  useEffect(() => {
    if (
      prevModelTypeRef.current !== 1 &&
      (buttonState.modelEquipment.length === 1 ||
        buttonState.modelType.length === 1)
    ) {
      prevModelTypeRef.current = 1;
      ScrollToElement("modelUsage");
    } else if (
      buttonState.modelEquipment.length === 0 &&
      buttonState.modelType.length === 0
    ) {
      prevModelTypeRef.current = 0;
    }
  }, [buttonState.modelEquipment, buttonState.modelType]);

  //Used to scroll to the correct section when the user click the button
  const prevModelUsageRef = useRef();
  const preMethodologyOutputsRef = useRef();
  useEffect(() => {
    if (
      buttonState.modelUsage.length === 1 &&
      prevModelUsageRef.current !== 1
    ) {
      prevModelUsageRef.current = 1;
      if (window.innerWidth < 1600) {
        ScrollToElement("uploadFiles0");
      }
    } else if (
      buttonState.methodologyOutputs.length === 1 &&
      preMethodologyOutputsRef.current !== 1
    ) {
      preMethodologyOutputsRef.current = 1;

      if (window.innerWidth < 1600) {
        ScrollToElement("uploadFiles0");
      }
    } else if (
      buttonState.modelUsage.length === 0 &&
      buttonState.methodologyOutputs.length === 0
    ) {
      prevModelUsageRef.current = 0;
      preMethodologyOutputsRef.current = 0;
    } else if (buttonState.modelUsage.length === 0) {
      prevModelUsageRef.current = 0;
    } else if (buttonState.methodologyOutputs.length === 0) {
      preMethodologyOutputsRef.current = 0;
    }
  }, [buttonState.modelUsage, buttonState.methodologyOutputs]);

  //Used to make sure the page scale correctly in different screen size
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Used for drag in upload method, while user drag in file hightlight the upload area
  useEffect(() => {
    const handleDragEnter = (e) => {
      e.preventDefault();
      dragCounter.current++;
      setIsDragOver(dragCounter.current > 0);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      dragCounter.current--;
      setIsDragOver(dragCounter.current > 0);
    };
    const handleDrop = (e) => {
      e.preventDefault();
      dragCounter.current = 0;
      setIsDragOver(false);
    };

    window.addEventListener("dragenter", handleDragEnter);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("drop", handleDrop);
    return () => {
      window.removeEventListener("dragenter", handleDragEnter);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("drop", handleDrop);
    };
  }, []);

  //Used to delete the temp request when user leave the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      deleteTempRequest();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  });

  const uploadDocumentProps = {
    customRequest: async ({
      file,
      onSuccess,
      onError,
      onProgress: onProgressInternal,
    }) => {
      const requestUid = location.state
        ? location.state.requestDetails.id
        : createdRequestUID;
      try {
        await uploadData({
          path: `public/${requestUid}/uploadedFile/${file.name}`,
          data: file,
          options: {
            onProgress: async (progressEvent) => {
              const percent = Math.round(
                (progressEvent.transferredBytes / progressEvent.totalBytes) *
                  100
              );
              onProgressInternal({ percent }, file);
              if (percent === 100) {
                file.path = `public/${requestUid}/uploadedFile/${file.name}`;
                await addFiles({
                  variables: {
                    name: file.name,
                    storage_path: file.path,
                    type: file.type,
                    uid: file.uid,
                    request_id: location.state
                      ? location.state.requestDetails.id
                      : createdRequestUID,
                  },
                }).catch((error) => {
                  console.log("Error ", error.message);
                });
              }
            },
          },
        }).result;
        onSuccess(file);
      } catch (error) {
        file.status = "error";
        onError(error, file);
      }
    },

    beforeUpload: (file) => {
      if (file.size > 1024 * 1024 * 1024) {
        message.error("File size should be less than 1GB");
        return Upload.LIST_IGNORE;
      }
      if (
        buttonState.fileDescription.find(
          (existingFile) => existingFile.name === file.name
        )
      ) {
        message.error(
          `A file with the name ${file.name} has already been uploaded.`
        );
        return Upload.LIST_IGNORE;
      }
    },

    onChange(info) {
      const { status, name } = info.file;
      if (status === "uploading") {
        const isFileAlreadyInState = buttonState.fileDescription.find(
          (existingFile) => existingFile.uid === info.file.uid
        );
        if (!isFileAlreadyInState) {
          dispatch({ type: "addFile", value: info.file });
        }
      }
      if (status === "error") {
        message.error(`${name} file upload failed.`);
      }
    },
    onRemove: (info) => {
      deleteFile({
        variables: {
          storage_path: info.storage_path ? info.storage_path : info.path,
        },
      });
      try {
        remove({
          path: info.storage_path ? info.storage_path : info.path,
        });
      } catch (error) {
        console.log("Error ", error);
      }
      dispatch({ type: "removeFile", value: info.uid });
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onProgress: (progress, file) => {
      dispatch({
        type: "editFileUploadProgress",
        key: file.uid,
        value: progress.percent,
      });
    },
    onSuccess: (file) => {
      dispatch({
        type: "FileUploadSuccess",
        value: file,
      });
      message.success(`${file.name} file uploaded successfully.`);
    },
    onError: (error, file) => {
      dispatch({
        type: "FileUploadError",
        value: file,
      });
      message.error(`${file.name} upload failed. Because ${error}`);
    },

    multiple: true,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 2,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const onFinish = async (values) => {
    const requestUid = location.state
      ? location.state.requestDetails.id
      : createdRequestUID;
    if (
      (RequestDetailData &&
        RequestDetailData.request_by_pk.status === "Creating") ||
      !location.state
    ) {
      setIsDetailRefetching(true);
      setIsListRefetching(true);
      const date = buttonState.dueDate
        ? dayjs(buttonState.dueDate).toISOString()
        : null;

      submitRequest({
        variables: {
          requestUid: requestUid,
          requestName: buttonState.requestName,
          dueDate: date,
          budget: buttonState.budget,
          outputFormat: buttonState.outputFormat,
          requiredSoftware: buttonState.requiredSoftware,
          status: "Pending",
          requestDescription: buttonState.requestDescription,
          workStreams: buttonState.workStreams,
          constructionMethodology: buttonState.constructionMethodology,
          methodologyBuilding: buttonState.methodologyBuilding,
          methodologyPhase: buttonState.methodologyPhase,
          methodologyOutputs: buttonState.methodologyOutputs,
          modelDevelopment: buttonState.modelDevelopment,
          modelPhase: buttonState.modelPhase,
          modelType: buttonState.modelType,
          modelUsage: buttonState.modelUsage,
          modelEquipment: buttonState.modelEquipment,
        },
      });

      modifyRequestFileList(requestUid);
    } else {
      modifyTempRequest();
    }
    setIsModalVisible(true);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const UploadModule = (Top) => {
    return (
      <React.Fragment>
        <FormItemAnimationContainer
          id={"uploadFiles" + Top}
          className={
            Top ? "show" : classNameDepender("uploadFiles", buttonState)
          }
        >
          <StyledFormItem
            label="Please Upload the files"
            name="uploadFiles"
            layout="vertical"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <div
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
                marginTop: -16,
              }}
            >
              <HighlightedDragger
                {...uploadDocumentProps}
                isDragOver={isDragOver}
                fileList={buttonState.fileDescription}
                itemRender={(originNode, file) => {
                  return (
                    <div style={{ width: "100%", height: "auto" }}>
                      <div
                        style={{
                          width: "35%",
                          float: "left",
                          marginBottom: 5,
                          height: 32,
                        }}
                      >
                        {originNode}
                      </div>
                      <Input
                        style={{
                          width: "60%",
                          float: "right",
                          marginTop: 5,
                        }}
                        onChange={(e) => {
                          const newFile = file;
                          newFile.description = e.target.value;

                          dispatch({
                            type: "addFileDescription",
                            value: newFile,
                          });
                        }}
                        placeholder={"Desciption of the file"}
                        defaultValue={file.description}
                      />
                      <div style={{ clear: "both" }}></div>
                    </div>
                  );
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  {buttonStateRef.current === buttonState
                    ? "Please answer the questions and there would be a recommanded uploaded file list."
                    : "Drawing, Sketches,Models, Meeting Notes, Past Work.(Displayed information will based on the previous selection.)"}
                </p>
              </HighlightedDragger>
            </div>
          </StyledFormItem>
        </FormItemAnimationContainer>
        <FormItemAnimationContainer
          className={classNameDepender("uploadFiles", buttonState)}
        >
          <StyledFormItem
            wrapperCol={{
              span: 24,
            }}
          >
            <div style={{ textAlign: "center", width: "100%" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginTop: 24,
                  marginBottom: 64,
                }}
              >
                Submit
              </Button>
            </div>
          </StyledFormItem>
        </FormItemAnimationContainer>
      </React.Fragment>
    );
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  //This function is used to handle search request, info?.source will return 'input'
  return (
    <MainHeader select="Request" warning={warning}>
      <Layout.Content
        style={{
          marginLeft: 2,
          minHeight: 150,
          background: colorBgContainer,
          height: height - 66,
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: height - 66,
          }}
          ref={contentRef}
        >
          <Form
            name="basic"
            labelAlign="left"
            labelWrap
            form={form}
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 18,
            }}
            style={{
              width: "100%",
              maxWidth: 0.9 * (width - 220),
              height: height - 66,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={{
              remember: true,
              projectName: buttonState.requestName,
              description: buttonState.requestDescription,
              outputFormat: buttonState.outputFormat,
              requiredSoftware: buttonState.requiredSoftware,
              dueDate: buttonState.dueDate,
              budget: buttonState.budget,
            }}
            onValuesChange={(changedValues, allValues) => {
              if (changedValues.projectName) {
                dispatch({
                  type: "modifyRequestName",
                  value: changedValues.projectName,
                });
              }
              if (changedValues.description) {
                dispatch({
                  type: "modifyRequestDescription",
                  value: changedValues.description,
                });
              }
              if (
                changedValues.outputFormat &&
                changedValues.outputFormat !== ""
              ) {
                dispatch({
                  type: "modifyOutputFormat",
                  value: changedValues.outputFormat,
                });
              }
              if (
                changedValues.requiredSoftware &&
                changedValues.requiredSoftware !== ""
              ) {
                dispatch({
                  type: "modifyRequiredSoftware",
                  value: changedValues.requiredSoftware,
                });
              }
              if (changedValues.dueDate && changedValues.dueDate !== "") {
                const date = dayjs(changedValues.dueDate).toISOString();
                dispatch({
                  type: "modifyDueDate",
                  value: date,
                });
              }
              if (changedValues.budget && changedValues.budget !== "") {
                dispatch({
                  type: "modifyBudget",
                  value: changedValues.budget,
                });
              }
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Row
                style={{
                  maxWidth: 3000,
                  display: "flex",
                  justifyContent: "center",
                  height: height - 66,
                }}
              >
                <StyledCol
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={20}
                  xxl={8}
                  style={{
                    position: window.innerWidth > 1600 ? "sticky" : "relative",
                    top: 0,
                    overflowY: window.innerWidth > 1600 ? "auto" : "hidden",
                    height: window.innerWidth > 1600 ? height - 66 : "auto",
                  }}
                >
                  <Row key={"vertical"}>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      style={{ paddingRight: "2vw" }}
                    >
                      <h1
                        style={{
                          fontSize: 30,
                          paddingBottom: 50,
                          paddingLeft: 0,
                          textAlign:
                            window.innerWidth > 1600 ? "left" : "center",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          type="text"
                          onClick={() => {
                            if (warning) {
                              Modal.confirm({
                                title: "Leave this page?",
                                content:
                                  "Do you want to save the progress you made for this request?",
                                onOk: () => {
                                  modifyTempRequest();
                                  navigate("/request_details", {
                                    state: {
                                      requestUid: location.state
                                        ? location.state.requestDetails.id
                                        : createdRequestUID,
                                    },
                                  });
                                },
                                onCancel: () => {
                                  deleteTempRequest();
                                  if (location.state) {
                                    navigate("/request_details", {
                                      state: {
                                        requestUid:
                                          location.state.requestDetails.id,
                                      },
                                    });
                                  } else {
                                    navigate("/request_list");
                                  }
                                },
                                onOkText: "Yes",
                                cancelText: "Don't save",
                              });
                            } else {
                              navigate("/request_list");
                            }
                          }}
                          icon={<ArrowLeftOutlined style={{ fontSize: 30 }} />}
                        />
                        <div style={{ flex: 1, textAlign: "center" }}>
                          {!location.state && <div>Create Your Request </div>}

                          {location.state && <div>Modify Your Request </div>}
                        </div>
                      </h1>
                      <Form.Item
                        label="Title"
                        name="projectName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your project name!",
                          },
                        ]}
                      >
                        <Input style={{ flexGrow: 1 }} />
                      </Form.Item>

                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your description of your project!",
                          },
                        ]}
                      >
                        <Input.TextArea span={4} />
                      </Form.Item>
                      <Form.Item label="Output Format" name="outputFormat">
                        <Input style={{ flexGrow: 1 }} />
                      </Form.Item>
                      <Form.Item label="Software" name="requiredSoftware">
                        <Input style={{ flexGrow: 1 }} />
                      </Form.Item>
                      <Form.Item label="Due Date" name="dueDate">
                        <DatePicker
                          style={{ width: "100%" }}
                          disabledDate={disabledPastDate}
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>
                      <Form.Item label="Budget" name="budget">
                        <InputNumber style={{ width: "100%" }} addonAfter="$" />
                      </Form.Item>
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={24}>
                      {UploadModule(1)}
                    </Col>
                  </Row>
                </StyledCol>

                <StyledCol
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={16}
                  style={{
                    overflowY: window.innerWidth > 1600 ? "auto" : "hidden",
                    height: window.innerWidth > 1600 ? height - 66 : "auto",
                    // marginTop: window.innerWidth > 1600 ? -130 : 0,
                  }}
                >
                  <StyledFormItem
                    label={RequestCreactionButtonData[0].question}
                    name={RequestCreactionButtonData[0].category}
                    layout="vertical"
                    className={"show"}
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{ paddingTop: 50 }}
                  >
                    <RequestShowCaseButton>
                      {RequestCreactionButtonData[0]}
                    </RequestShowCaseButton>
                  </StyledFormItem>
                  {RequestCreactionButtonData.slice(1).map((item) => {
                    let className = classNameDepender(
                      item.category,
                      buttonState
                    );
                    return (
                      <FormItemAnimationContainer
                        id={item.category}
                        className={className}
                      >
                        <StyledFormItem
                          label={item.question}
                          name={item.category}
                          Layout="vertical"
                          labelCol={{
                            span: 24,
                          }}
                          wrapperCol={{
                            span: 24,
                          }}
                        >
                          <RequestShowCaseButton>{item}</RequestShowCaseButton>
                        </StyledFormItem>
                      </FormItemAnimationContainer>
                    );
                  })}
                </StyledCol>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={0}>
                  {UploadModule(0)}
                </Col>
              </Row>
            </div>
          </Form>
        </div>

        <Modal
          title={
            <>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ paddingRight: 12 }}
              />
              Successful
            </>
          }
          visible={showModal}
          onOk={handleModalOk}
          onCancel={handleModalOk}
          footer={[
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Button key="confirm" type="primary" onClick={handleModalOk}>
                Confirm
              </Button>
            </div>,
          ]}
        >
          <div style={{ paddingTop: 12 }}>
            {!location.state && (
              <div> Request has been submitted successfully!</div>
            )}
            {location.state && (
              <div> Request has been modified successfully!</div>
            )}
          </div>
          <div style={{ paddingBottom: 24 }}>
            We will get back to you as soon as possible.
          </div>
        </Modal>
        <Spin
          spinning={RequestDetailLoading || RequestListLoading}
          fullscreen
        />
      </Layout.Content>
    </MainHeader>
  );
}

export default withThemeSwitch(RequestCreation);

const StyledCol = styled(Col)`
  position: relative;
  overflow-y: auto;
  height: 75vh;

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: darkgrey transparent;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 10px;
    position: absolute;
    right: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: darkgrey;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin: 0 !important;

  .ant-form-item-label {
    text-align: center;
  }
  .ant-form-item-label > label {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24;
    height: auto;
  }
`;
const FormItemAnimationContainer = styled.div`
  width: 100%;
  opacity: 0;
  max-height: 0;
  overflow: hidden;

  transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out,
    padding 0.3s ease-in-out;
  &.show {
    opacity: 1;
    max-height: 1500px;
  }
`;
const HighlightedDragger = styled(Dragger)`
  .ant-upload.ant-upload-drag {
    transition: box-shadow 0.3s ease-in-out;
    max-height: 200;

    overflow-y: hidden;
    margin-top: 30 !important;
    margin-bottom: 30 !important;
    box-shadow: ${(props) =>
      props.isDragOver
        ? "0 0 20px rgba(24, 144, 255, 0.5)"
        : "none"} !important;
  }
  .ant-upload-list {
    margin-top: -30;
    margin-bottom: 30 !important;
  }
`;
