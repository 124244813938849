import MainHeader from "../Component/Header";
import Footer from "../Component/Footer";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  theme,
  Input,
  Popover,
  Button,
  DatePicker,
  Form,
  Table,
  Space,
  Modal,
  message,
  Tooltip,
  Empty,
} from "antd";
import {
  ControlOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { useGlobalContext } from "../Component/GlobalContext";
import withThemeSwitch from "../Component/withThemeSwitch";
import { gql, useMutation, useQuery } from "@apollo/client";

var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
const { Search } = Input;
const { RangePicker } = DatePicker;

const GET_PROJECT_LIST = gql`
  query Query($user_id: uuid!) {
    project(where: { user_id: { _eq: $user_id } }) {
      id
      name
    }
  }
`;

const CREATE_PROJECT = gql`
  mutation Mutation($name: String!, $user_id: uuid!) {
    insert_project_one(object: { name: $name, user_id: $user_id }) {
      id
    }
  }
`;
const DELETE_PROJECT = gql`
  mutation Mutation($id: uuid!) {
    delete_project_by_pk(id: $id) {
      id
    }
  }
`;

function ProjectList() {
  const [height, setHeight] = useState(window.innerHeight);
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [fetchData, setFetchData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [inputProjectName, setInputProjectName] = useState("");

  const {
    RequestListData: data,
    fetchRequestList,
    RequestListLoading: loading,
    userId,
    setSelectedProject,
  } = useContext(useGlobalContext);

  const {
    data: projectList,
    refetch,
    loading: projectListLoading,
  } = useQuery(GET_PROJECT_LIST, {
    variables: { user_id: userId },
  });
  const [createProject] = useMutation(CREATE_PROJECT);
  const [deleteProject] = useMutation(DELETE_PROJECT);

  useEffect(() => {
    if (!data || !projectList) return;
    setSelectedProject(null);
    const groupedRequests = projectList.project.map((project) => ({
      project_id: project.id,
      project_name: project.name,
      latest_due_date: null,
      latest_update_date: null,
      total_unread_messages: 0,
      requestAmount: 0,
    }));

    // update the groupedRequests with the latest due date, update date, and total unread <messages></messages>
    data.get_request_list_by_user_id.forEach((current) => {
      const existingProject = groupedRequests.find(
        (item) => item.project_id === current.project_id
      );

      if (existingProject) {
        if (
          current.due_date &&
          (!existingProject.latest_due_date ||
            new Date(current.due_date) >
              new Date(existingProject.latest_due_date))
        ) {
          existingProject.latest_due_date = current.due_date;
        }
        if (
          current.update_date &&
          (!existingProject.latest_update_date ||
            new Date(current.update_date) >
              new Date(existingProject.latest_update_date))
        ) {
          existingProject.latest_update_date = current.update_date;
        }
        existingProject.total_unread_messages +=
          current.user_unread_message_count;
        existingProject.requestAmount += 1;
      }
    });

    const formattedRequests = groupedRequests.map((element) => ({
      key: element.project_id,
      title: element.project_name,
      deadline: element.latest_due_date
        ? new Date(element.latest_due_date).toLocaleDateString("en-NZ")
        : "TBD",
      update: element.latest_update_date
        ? new Date(element.latest_update_date).toLocaleDateString("en-NZ")
        : "TBD",
      unreadMessageCount: element.total_unread_messages,
      requestAmount: element.requestAmount, // request amount
    }));
    console.log(formattedRequests);
    setFetchData(formattedRequests);
    setProjects(formattedRequests);
  }, [data, projectList, setSelectedProject]);

  useEffect(() => {
    fetchRequestList({ variables: { user_id: userId } });
  }, [userId, fetchRequestList]);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  //This function is used to handle search request, info?.source will return 'input'
  const onSearch = (value, _e, info) => {
    setProjects(
      fetchData.filter((project) => {
        return project.title.toLowerCase().includes(value.toLowerCase());
      })
    );
  };
  const onFilterFinish = (values) => {
    let searchResult = fetchData;
    if (values.status && values.status.length > 0) {
      searchResult = searchResult.filter((project) =>
        values.status.some((status) => project.status.includes(status))
      );
    }
    if (values.deadline) {
      searchResult = searchResult.filter((project) => {
        if (project.deadline) {
          return dayjs(project.deadline, "DD MM YYYY").isBetween(
            values.deadline[0],
            values.deadline[1]
          );
        } else {
          return false;
        }
      });
    }
    if (values.Updated) {
      searchResult = searchResult.filter((project) => {
        if (project.update) {
          return dayjs(project.update, "DD MM YYYY").isBetween(
            values.Updated[0],
            values.Updated[1]
          );
        } else {
          return false;
        }
      });
    }
    setProjects(searchResult);
  };
  const onFilterFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const filter = (
    <Form
      name="basic"
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 350,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFilterFinish}
      onFinishFailed={onFilterFinishFailed}
      autoComplete="off"
    >
      <Form.Item label="Deadline" name="deadline">
        <RangePicker />
      </Form.Item>
      <Form.Item label="Updated" name="Updated">
        <RangePicker />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button htmlType="submit">Apply Filiter</Button>
      </Form.Item>
    </Form>
  );
  const columns = [
    {
      title: "Project Title",
      dataIndex: "title",
      key: "title",
      render: (_, { title, unreadMessageCount, requestAmount, key }) => {
        return (
          <Space
            direction="horizontal"
            style={{ height: "100%", alignItems: "center" }}
          >
            <div
              style={{
                width: "100%",
                float: "left",
                height: "100%",
                alignItems: "center",
                display: "flex",
              }}
            >
              {title}
            </div>

            {unreadMessageCount !== 0 && (
              <div
                style={{
                  marginLeft: 35,
                  fontWeight: "bold",
                  display: "flex",
                  borderRadius: "50%",
                  backgroundColor: "red",
                  color: "white",
                  fontSize: unreadMessageCount > 99 ? 9 : 11,
                  width: 20,
                  height: 20,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {unreadMessageCount > 99 ? "99+" : unreadMessageCount}
              </div>
            )}
            {requestAmount === 0 && (
              <Button
                type="text"
                icon={<DeleteOutlined style={{ color: "#e25050" }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteProject({ variables: { id: key } }).then(() => {
                    refetch();
                  });
                }}
              />
            )}
          </Space>
        );
      },
    },
    {
      title: "Due date",
      dataIndex: "deadline",
      key: "deadline",
      render: (text) => (text === null ? <p>TBD</p> : <p>{text}</p>),
      sorter: (a, b) => {
        const dateA = dayjs(a.deadline, "DD MM YYYY");
        const dateB = dayjs(b.deadline, "DD MM YYYY");

        if (!dateA.isValid()) {
          return -1;
        }
        if (!dateB.isValid()) {
          return 1;
        }

        return dateA.unix() - dateB.unix();
      },
    },

    {
      title: "Updated date",
      dataIndex: "update",
      key: "update",
      render: (text) => (text === null ? <p>TBD</p> : <p>{text}</p>),
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        const dateA = dayjs(a.update, "DD MM YYYY");
        const dateB = dayjs(b.update, "DD MM YYYY");

        if (!dateA.isValid()) {
          return 1;
        }
        if (!dateB.isValid()) {
          return -1;
        }

        return dateA.unix() - dateB.unix();
      },
    },
    {
      title: "Request amount",
      dataIndex: "requestAmount",
      key: "requestAmount",
      sorter: (a, b) => a.requestAmount - b.requestAmount,
      render: (text) => <p>{text}</p>,
    },
  ];
  return (
    <MainHeader select="Request">
      <Layout.Content
        style={{
          padding: 24,
          marginLeft: 2,
          minHeight: 280,
          background: colorBgContainer,
          height: height - 135,
        }}
      >
        <div>
          <div
            style={{
              textAlign: "center",
              direction: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div />
            <div style={{ alignItems: "center", display: "flex" }}>
              <Popover
                content={filter}
                placement="bottomLeft"
                trigger="click"
                arrow={false}
              >
                <ControlOutlined style={{ fontSize: 26, marginRight: 30 }} />
              </Popover>

              <Search
                placeholder="search project by title"
                onSearch={onSearch}
                style={{ width: 400 }}
              />
            </div>
            <Tooltip title="Create New Project" placement="left">
              <Button
                type="text"
                style={{ float: "right", marginRight: "1vw" }}
                icon={<PlusOutlined style={{ fontSize: 26 }} />}
                onClick={() => setModalVisible(true)}
              />
            </Tooltip>
          </div>
        </div>
        <Table
          style={{ paddingTop: 24 }}
          columns={columns}
          loading={loading || projectListLoading}
          dataSource={projects}
          locale={{
            emptyText: (
              <Empty description="You don't have a project yet">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setModalVisible(true)}
                >
                  Create Project
                </Button>
              </Empty>
            ),
          }}
          pagination={false}
          virtual
          scroll={{ y: height - 280 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                setSelectedProject(record.key);
                navigate("/request_list");
              },
            };
          }}
        />
      </Layout.Content>

      <Footer />
      <Modal
        title="Create a new project"
        visible={modalVisible}
        centered
        style={{ textAlign: "center" }}
        onOk={() => {
          if (inputProjectName.length > 0) {
            createProject({
              variables: { name: inputProjectName, user_id: userId },
            }).then(() => {
              refetch();
              setModalVisible(false);
            });
          } else {
            message.error("Please enter a project name");
          }
        }}
        onCancel={() => setModalVisible(false)}
      >
        <Input
          size="large"
          placeholder="Project Name"
          style={{ width: "80%", marginTop: "10%", marginBottom: "10%" }}
          onChange={(e) => setInputProjectName(e.target.value)}
        />
      </Modal>
    </MainHeader>
  );
}

export default withThemeSwitch(ProjectList);
