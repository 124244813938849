export const RequestCreactionButtonData = [
  {
    question: "What type of work stream would you like to choose?",
    category: "workStreams",
    subset: ["constructionMethodology", "modelDevelopment"],
    selectionContent: [
      {
        title: "Construction Methodology",
        image: "/Build Craft Photos/Construction Methodology Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "constructionMethodology",
      },
      {
        title: "Model Development",
        image: "/Build Craft Photos/Model Development Photo.png",
        information:
          "We provide model development services to help you build your project.",
        confirmType: "modelDevelopment",
      },
    ],
  },

  {
    question:
      "What types of construction methodology services would you like to choose?",
    category: "constructionMethodology",
    subset: "methodologyBuilding",
    selectionContent: [
      {
        title: "Developing",
        image: "/Build Craft Photos/Development Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "methodologyDeveloping",
      },
      {
        title: "Verifying",
        image: "/Build Craft Photos/Verifying Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "methodologyVerifying",
      },
      {
        title: "Communicate",
        image: "/Build Craft Photos/Communicating Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "methodologyCommunicate",
      },
    ],
  },

  {
    question: "What type of building would you like to choose?",
    category: "methodologyBuilding",
    subset: "methodologyPhase",
    selectionContent: [
      {
        title: "Bridge",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "methodologyBuildingBridge",
      },
      {
        title: "Road",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "methodologyBuildingRoad",
      },
      {
        title: "Retaining Wall",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "methodologyBuildingRW",
      },
      {
        title: "Drainage",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "methodologyBuildingDrainage",
      },
      {
        title: "Multi-Storey Building",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "methodologyBuildingMSB",
      },
    ],
  },

  {
    question: "What is your project current phase?",
    category: "methodologyPhase",
    subset: "methodologyOutputs",
    selectionContent: [
      {
        title: "Concept",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "methodologyPhaseConcept",
      },
      {
        title: "Tender",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "methodologyPhaseTender",
      },
      {
        title: "ECI",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "methodologyPhaseECI",
      },
      {
        title: "Construction",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "methodologyPhaseConstruction",
      },
    ],
  },

  {
    question: "What type of output would you like to choose?",
    category: "methodologyOutputs",
    subset: "uploadFiles",
    selectionContent: [
      {
        title: "Technical Drawings",
        image: "/Build Craft Photos/Technical Drawings Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        category: "methodologyOutputs",
        confirmType: "methodologyOutputsTD",
      },
      {
        title: "Rendered Drawings",
        image: "/Build Craft Photos/Rendered Drawings Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        category: "methodologyOutputs",
        confirmType: "methodologyOutputsRD",
      },
      {
        title: "Figures for written documents",
        image: "/placeholder.jpg",
        information:
          "We provide construction methodology services to help you build your project.",
        category: "methodologyOutputs",
        confirmType: "methodologyOutputsFWD",
      },
      {
        title: "Animations",
        image: "/Build Craft Photos/Animation Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        category: "methodologyOutputs",
        confirmType: "methodologyOutputsAnimations",
      },
      {
        title: "Simulations",
        image: "/Build Craft Photos/Simulation Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        category: "methodologyOutputs",
        confirmType: "methodologyOutputsSimulations",
      },
    ],
  },

  {
    question:
      "What type of model development services would you like to choose?",
    category: "modelDevelopment",
    subset: "modelPhase",
    selectionContent: [
      {
        title: "Temporary Works",
        image: "/placeholder2.jpg",
        information:
          "We provide model development services to help you build your project.",

        confirmType: "modelTemporaryWorks",
      },
      {
        title: "Permanent Works",
        image: "/Build Craft Photos/Permanent Works Photo.png",
        information:
          "We provide model development services to help you build your project.",

        confirmType: "modelPermanentWorks",
      },
      {
        title: "Equipment",
        image: "/Build Craft Photos/Equipment Photo.png",
        information:
          "We provide model development services to help you build your project.",

        confirmType: "modelEquipment",
      },
    ],
  },

  {
    question: "What is your project current phase?",
    category: "modelPhase",
    subset: "modelType",
    selectionContent: [
      {
        title: "Concept",
        image: "/placeholder2.jpg",
        information:
          "We provide model development services to help you build your project.",

        confirmType: "modelConcept",
      },
      {
        title: "Tender",
        image: "/placeholder2.jpg",
        information:
          "We provide model development services to help you build your project.",

        confirmType: "modelTender",
      },
      {
        title: "ECI",
        image: "/placeholder2.jpg",
        information:
          "We provide model development services to help you build your project.",

        confirmType: "modelECI",
      },
      {
        title: "Construction",
        image: "/placeholder2.jpg",
        information:
          "We provide model development services to help you build your project.",

        confirmType: "modelConstruction",
      },
    ],
  },

  {
    question: "What type of model would you like to choose?",
    category: "modelType",
    subset: "modelEquipment",
    selectionContent: [
      {
        title: "Bridge",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "modelTypeBridge",
      },
      {
        title: "Road",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "modelTypeRoad",
      },
      {
        title: "Retaining Wall",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "modelTypeRW",
      },
      {
        title: "Drainage",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "modelTypeDrainage",
      },
      {
        title: "Multi-Storey Building",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",

        confirmType: "modelTypeMSB",
      },
    ],
  },
  {
    question: "What type of equipment would you like to choose?",
    category: "modelEquipment",
    subset: "modelUsage",
    selectionContent: [
      {
        title: "Excavator",
        image: "/Build Craft Photos/Excavator Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "modelExcavator",
      },
      {
        title: "Piling Rig",
        image: "/Build Craft Photos/Piling Rig Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "modelPilingRig",
      },
      {
        title: "TBM",
        image: "/Build Craft Photos/TBM Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "modelTBM",
      },
      {
        title: "Crane",
        image: "/Build Craft Photos/Crane Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "modelCrane",
      },
    ],
  },
  {
    question: "What is the purpose of the model?",
    category: "modelUsage",
    subset: "uploadFiles",
    selectionContent: [
      {
        title: "Developing",
        image: "/Build Craft Photos/Development Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "modelUsageDeveloping",
      },
      {
        title: "Verifying",
        image: "/Build Craft Photos/Verifying Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "modelUsageVerifying",
      },
      {
        title: "Communicate",
        image: "/Build Craft Photos/Communicating Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "modelUsageCommunicate",
      },
      {
        title: "Fabrication",
        image: "/placeholder2.jpg",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "modelUsageFabrication",
      },
      {
        title: "Quantities",
        image: "/Build Craft Photos/Model Development Photo.png",
        information:
          "We provide construction methodology services to help you build your project.",
        confirmType: "modelUsageQuantities",
      },
    ],
  },
];
