import { Layout, ConfigProvider, theme } from "antd";
// import { useNavigate } from "react-router-dom";

export default function HomeFooter() {
  // const navigate = useNavigate();

  return (
    <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
      <Layout.Footer style={{ textAlign: "center", marginTop: "10vh" }}>
        <div>
          ©{new Date().getFullYear()} RF Sons Engineering. All Rights Reserved
        </div>

        {/* <Button
          style={{ marginTop: 20 }}
          onClick={(e) => {
            navigate("/request_list");
          }}
        >
          Try Our BuildCraft!{" "}
        </Button> */}
      </Layout.Footer>
    </ConfigProvider>
  );
}
