import MainHeader from "../Component/Header";
import { Layout, theme, Button, Spin, Col, Row, Statistic, Tag } from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useGlobalContext } from "../Component/GlobalContext";
import { gql, useMutation } from "@apollo/client";

import withThemeSwitch from "../Component/withThemeSwitch";
import Message from "../Component/RequestComponent/Message";

function getStatusColor(status) {
  switch (status) {
    case "New Updated":
      return "green";
    case "On Progress":
      return "cyan";
    case "Finished":
      return "red";
    case "Pending":
      return "orange";
    case "Paid":
      return "blue";
    case "Creating":
      return "purple";
    default:
      return "grey";
  }
}

const DELETE_REQUEST = gql`
  mutation DeleteRequest($requestUid: uuid!) {
    delete_request_by_pk(id: $requestUid) {
      id
    }
  }
`;

function RequestDetails() {
  const [height, setHeight] = useState(window.innerHeight);

  const [navigateToList, setNavigateToList] = useState(false);

  const location = useLocation();
  const requestUid = location.state?.requestUid;
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const [requestDetails, setRequestDetails] = useState({});

  const {
    RequestDetailData: data,
    fetchRequestDetail: fetchData,
    RequestDetailLoading: loading,
    RequestListLoading,
    deleteMessageTempUpload,
    handleRefetchRequestList,
    setIsListRefetching,
    userId,
  } = useContext(useGlobalContext);

  const [deleteRequest] = useMutation(DELETE_REQUEST);

  useEffect(() => {
    if (navigateToList && !RequestListLoading) {
      navigate("/request_list");
    }
  });

  //This useEffect is used to convert the fetched data to the displayed data

  useEffect(() => {
    if (data) {
      var detail = {};
      if (data.request_by_pk) {
        const dueDate = new Date(
          data.request_by_pk.due_date
        ).toLocaleDateString("en-NZ");
        detail = {
          id: data.request_by_pk.id,
          actualPrice: data.request_by_pk.actual_price,
          dueDate: data.request_by_pk.due_date ? dueDate : "-",
          estimatePrice: data.request_by_pk.estimate_price
            ? data.request_by_pk.estimate_price
            : "TBD",
          status: data.request_by_pk.status,
          updateDate: data.request_by_pk.update_date,
          requestName: data.request_by_pk.title,
          requestDescription: data.request_by_pk.description,
          workStreams: data.request_by_pk.work_streams,
          constructionMethodology: data.request_by_pk.construction_methodology,
          methodologyBuilding: data.request_by_pk.methodology_building,
          methodologyPhase: data.request_by_pk.methodology_phase,
          methodologyOutputs: data.request_by_pk.methodology_outputs,
          modelDevelopment: data.request_by_pk.model_development,
          modelPhase: data.request_by_pk.model_phase,
          modelEquipment: data.request_by_pk.model_equipment,
          modelType: data.request_by_pk.model_type,
          modelUsage: data.request_by_pk.model_usage,
          downloadLink: data.request_by_pk.download_link
            ? data.request_by_pk.download_link
            : "-",
          budget: data.request_by_pk.budget,
          outputFormat: data.request_by_pk.output_format,
          requiredSoftware: data.request_by_pk.required_software,
          fileDescription: [],
        };
      } else {
        detail = {
          id: data.id,
          actualPrice: data.actual_price,
          dueDate: data.due_date,
          estimatePrice: data.estimate_price,
          status: data.status,
          updateDate: data.update_date,
          requestName: data.title,
          requestDescription: data.description,
          workStreams: data.work_streams,
          constructionMethodology: data.construction_methodology,
          methodologyBuilding: data.methodology_building,
          methodologyPhase: data.methodology_phase,
          methodologyOutputs: data.methodology_outputs,
          modelDevelopment: data.model_development,
          modelPhase: data.model_phase,
          modelEquipment: data.model_equipment,
          modelType: data.model_type,
          modelUsage: data.model_usage,
          downloadLink: data.download_link,
          budget: data.budget,
          outputFormat: data.output_format,
          requiredSoftware: data.required_software,
          fileDescription: [],
        };
      }
      setRequestDetails(detail);
    }
  }, [data]);

  //This useEffect is used to prevent user directly access the page without login

  useEffect(() => {
    if (isMounted) {
      if (requestUid === undefined) {
        navigate("/request_list");
      } else {
        fetchData({
          variables: { requestUid: requestUid },
        });
      }
    }
  }, [isMounted, navigate, requestUid, fetchData]);

  useEffect(() => {
    setIsMounted(true);
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const {
    token: { colorBgContainer, colorBorder },
  } = theme.useToken();
  // Used to delete uploaded images when close the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      deleteMessageTempUpload();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  });
  // Used to delete uploaded images when leaving the page

  return (
    <MainHeader select="Request">
      {loading && (
        <div
          style={{
            width: "100%",
            height: height - 66,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: colorBgContainer,
          }}
        >
          <Spin tip="Loading..." size="large" />
        </div>
      )}
      {!loading && (
        <Layout.Content
          style={{
            marginLeft: 2,
            minHeight: 280,
            background: colorBgContainer,
            height: height - 66,
            overflowY: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              height: 100,
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Button
              type="text"
              icon={<ArrowLeftOutlined style={{ fontSize: 30 }} />}
              style={{ position: "absolute", left: "2vw" }}
              onClick={() => {
                deleteMessageTempUpload();
                navigate("/request_list");
              }}
            />
            <h1 style={{ margin: " auto" }}>
              {requestDetails.requestName}
              <Button
                type="text"
                style={{ marginLeft: "3vw" }}
                icon={<EditOutlined style={{ fontSize: 24 }} />}
                onClick={() => {
                  deleteMessageTempUpload();
                  navigate("/request_creation", {
                    state: {
                      requestDetails: requestDetails,
                    },
                  });
                }}
              />
            </h1>
            <Button
              type="text"
              icon={
                <DeleteOutlined style={{ fontSize: 26, color: "#e25050" }} />
              }
              style={{
                position: "absolute",
                right: "2vw",
                display:
                  requestDetails.status === "Creating" ? "block" : "none",
              }}
              onClick={() => {
                deleteRequest({
                  variables: { requestUid: requestUid },
                })
                  .then(() => {
                    setIsListRefetching(true);
                    setNavigateToList(true);
                    handleRefetchRequestList({ id: userId });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            />
          </div>
          <div style={{ display: "flex", paddingTop: 20 }}>
            <div
              style={{
                width: "20vw",
                paddingLeft: "2vw",
                paddingRight: "2vw",
                borderTopWidth: 1,
                borderTopColor: colorBorder,
                borderTopStyle: "solid",
                overflowY: "auto",
                height: height - 186,
              }}
            >
              <Row gutter={16} style={{ paddingTop: "2vh" }}>
                <Col span={12}>
                  <Statistic
                    title="State"
                    prefix={
                      <Tag
                        color={getStatusColor(requestDetails.status)}
                        style={{ fontSize: 18, padding: "5px 10px" }}
                      >
                        {requestDetails.status}
                      </Tag>
                    }
                    value=" "
                    valueStyle={{
                      color: getStatusColor(requestDetails.status),
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic title="Due Date" value={requestDetails.dueDate} />
                </Col>
                <Col span={12} style={{ paddingTop: "2vh" }}>
                  <Statistic
                    title="Actual price"
                    value={requestDetails.actualPrice}
                  />
                </Col>
                <Col span={12} style={{ paddingTop: "2vh" }}>
                  <Statistic
                    title="Estimate price"
                    value={requestDetails.estimatePrice}
                  />
                </Col>
                <Col span={12} style={{ paddingTop: "2vh" }}>
                  <Statistic
                    title="Budget"
                    value={
                      requestDetails.budget === null ||
                      requestDetails.budget === ""
                        ? "-"
                        : requestDetails.budget
                    }
                  />
                </Col>
              </Row>

              <Statistic
                title="Download Output Files"
                prefix={
                  requestDetails.downloadLink === "-" ? null : (
                    <div style={{ width: "100%" }}>
                      <a
                        href={requestDetails.downloadLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "block",
                          width: "16vw",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {requestDetails.downloadLink}
                      </a>
                    </div>
                  )
                }
                value={requestDetails.downloadLink === "-" ? "-" : " "}
                valueStyle={{ fontSize: 16 }}
                style={{ paddingTop: "2vh" }}
              />

              <Statistic
                title="Description"
                value={requestDetails.requestDescription}
                valueStyle={{ fontSize: 16 }}
                style={{ paddingTop: "2vh" }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "80%",
                height: height - 186,
                borderWidth: 1,
                borderColor: colorBorder,
                borderStyle: "solid",
              }}
            >
              <Message chatroomId={requestUid} height={height - 186} />
            </div>
          </div>
        </Layout.Content>
      )}
    </MainHeader>
  );
}

export default withThemeSwitch(RequestDetails);
