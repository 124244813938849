import { Layout } from "antd";

export default function Footer() {
  return (
    <Layout>
      <Layout.Footer style={{ textAlign: "center" }}>
        ©{new Date().getFullYear()} RF Sons Engineering. All Rights Reserved
      </Layout.Footer>
    </Layout>
  );
}
