import styled, { keyframes } from "styled-components";

const fadeInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const TitleContainer = styled.div`
  opacity: 0;
  color: white;
  padding-top: ${(props) => (props.ismobile ? "5vh" : "10vh")};
  padding-left: 10vw;
  padding-right: 10vw;
  font-size: calc(
    100vh * ${(props) => (props.ismobile ? 36 : 72)} /
      ${(props) => (props.ismobile ? 720 : 1080)}
  );
  font-style: arial;
  font-weight: 700;
  line-height: 1.3;
  animation: ${fadeInFromLeft} 1.5s forwards;
`;
export const PageContainer = styled.div`
  background: linear-gradient(#00132c 50%, #181818);
  overflow-x: auto;
`;

export const SectionContainer = styled.div`
  width: 100%;
  overflow-y: hidden;
`;
export const FirstSectionContainer1 = styled(SectionContainer)`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
  background-image: ${(props) =>
    props.ismobile
      ? `linear-gradient( rgba(0, 0, 0, 0) 50%, #00132c), url("${props.bg}")`
      : `linear-gradient( rgba(0, 0, 0, 0) 50%, #00132c), url("${props.bg}")`};

  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const FirstSectionContainer = styled(SectionContainer)`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
  background-image: ${(props) =>
    props.ismobile
      ? `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)), url("${props.bg}")`
      : `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url("${props.bg}")`};

  background-position: right;
  background-repeat: no-repeat;
  background-size: ${(props) => (props.ismobile ? "cover" : "contain")};
`;
export const SubPageTitle = styled.div`
  font-size: calc(
    100vh * ${(props) => (props.ismobile ? 36 : 48)} /
      ${(props) => (props.ismobile ? 720 : 1080)}
  );
  font-weight: 400;
  color: white;
  padding-top: ${(props) => (props.ismobile ? "5vh" : "10vh")};
  padding-bottom: ${(props) => (props.ismobile ? "5vh" : "10vh")};
  width: 100%;
  text-align: center;
  margin-left: -0.5vw;
`;
export const DetailsContainer = styled.div`
  opacity: 0;
  color: white;
  padding-top: 10vh;
  padding-left: 10vw;
  font-size: calc(
    100vh * ${(props) => (props.ismobile ? 14 : 24)} /
      ${(props) => (props.ismobile ? 720 : 1080)}
  );
  font-style: arial;
  width: auto;
  animation: ${fadeInFromLeft} 1.5s forwards;
`;
