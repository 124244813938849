import Router from "./Router";
import { createContext, React, useContext, useReducer, useState } from "react";
import { theme, App } from "antd";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import GlobalContext from "./Component/GlobalContext";

const ThemeContext = createContext(null);

const createApolloClient = (authToken) => {
  return new ApolloClient({
    link: new WebSocketLink({
      uri: "wss://selected-pipefish-60.hasura.app/v1/graphql",
      options: {
        reconnect: true,
        connectionParams: {
          headers: {
            "x-hasura-admin-secret": authToken,
          },
        },
      },
    }),
    cache: new InMemoryCache(),
  });
};

const initialTheme = {
  theme: theme.defaultAlgorithm,
  name: "light",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "lightMode":
      return { theme: theme.defaultAlgorithm, name: "light" };
    case "darkMode":
      return { theme: theme.darkAlgorithm, name: "dark" };
    default:
      throw new Error();
  }
};

const ThemeSwitchProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialTheme);
  return (
    <ThemeContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
};

export default function MyApp() {
  const [client] = useState(
    createApolloClient(process.env.REACT_APP_HASURA_ADMIN_SECRET)
  );
  return (
    <ApolloProvider client={client}>
      <App>
        <ThemeSwitchProvider>
          <GlobalContext>
            <Router />
          </GlobalContext>
        </ThemeSwitchProvider>
      </App>
    </ApolloProvider>
  );
}
