import { useParams } from "react-router-dom";
import {
  HomeConstructionMethodology,
  Home3DModeling,
  HomeSiteSimulations,
  defaultData,
} from "./StaticData/HomeServiceData";
import { services } from "./StaticData/HomeData";
import { useState, useEffect } from "react";
import HomeHeader from "../Component/HomeComponent/HomeHeader";
import ServiceCardRow from "../Component/HomeComponent/ServiceCardRow";
import { useLocation } from "react-router-dom";
import ArrowCarousel from "../Component/HomeComponent/ArrowCarousel";
import HomeFooter from "../Component/HomeComponent/HomeFooter";
import {
  PageContainer,
  FirstSectionContainer,
  TitleContainer,
  DetailsContainer,
  SectionContainer,
  SubPageTitle,
} from "../Component/HomeComponent/GeneralComponents";
import TransparentCard from "../Component/HomeComponent/TransparentCard";

export default function HomeService() {
  let { page } = useParams();
  const [ismobile, setIsMobile] = useState(0);
  const location = useLocation();
  const [key, setKey] = useState(0);
  const [data, setData] = useState(defaultData);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    setKey((prevKey) => prevKey + 1);
  }, [location]);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.innerHeight > window.innerWidth ? 1 : 0);
    window.addEventListener("resize", handleResize);
    // Call handleResize right away so that ismobile is set correctly initially
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    switch (page) {
      case "construction_methodology":
        setData(HomeConstructionMethodology);
        break;
      case "3d_modeling":
        setData(Home3DModeling);
        break;
      case "site_simulations":
        setData(HomeSiteSimulations);
        break;
      default:
        break;
    }
  }, [page]);

  return (
    <PageContainer key={key}>
      <FirstSectionContainer ismobile={ismobile} bg={data.img}>
        <HomeHeader style={{ position: "relative", zindex: "2" }} />

        <TitleContainer ismobile={ismobile} style={{ paddingTop: "15vh" }}>
          {data.title}
        </TitleContainer>
        <DetailsContainer
          ismobile={ismobile}
          style={{
            paddingTop: ismobile ? "5vh" : "10vh",
            width: ismobile ? "80vw" : "40vw",
            lineHeight: 1.2,
            fontWeight: 400,
          }}
        >
          {data.description}
        </DetailsContainer>
      </FirstSectionContainer>
      <SectionContainer>
        <SubPageTitle ismobile={ismobile}>Why is it valuable?</SubPageTitle>
        <ServiceCardRow>{data.content.slice(0, 3)}</ServiceCardRow>
      </SectionContainer>
      <SectionContainer>
        <SubPageTitle ismobile={ismobile}>How do we do it?</SubPageTitle>
        <ServiceCardRow>{data.content.slice(3, 6)}</ServiceCardRow>
      </SectionContainer>
      <SectionContainer>
        <SubPageTitle ismobile={ismobile}>More of Our Services</SubPageTitle>
        <ArrowCarousel
          more={true}
          style={{
            width: ismobile ? "80vw" : "50vw",
            height: ismobile ? "50vh" : "80vh",
            margin: "auto",
            display: "flex",
            marginTop: ismobile ? 0 : "-10vh",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {services.map((service, index) => {
            if (service.link.split("/").pop() !== page) {
              return (
                <TransparentCard
                  option={service}
                  key={index + service.title}
                  zoom={0}
                />
              );
            } else {
              return null;
            }
          })}
        </ArrowCarousel>
      </SectionContainer>
      <HomeFooter />
    </PageContainer>
  );
}
