import {
  theme,
  List,
  Space,
  Input,
  Flex,
  Image,
  Upload,
  Spin,
  message,
  Button,
} from "antd";
import {
  SendOutlined,
  LinkOutlined,
  LoadingOutlined,
  SmileOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import withThemeSwitch from "../withThemeSwitch";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { useGlobalContext } from "../GlobalContext";
import { gql, useMutation, useSubscription } from "@apollo/client";
import { uploadData, getUrl, remove } from "aws-amplify/storage";
import styled, { keyframes } from "styled-components";

const UPDATE_CHATROOM = gql`
  mutation MyMutation($input_chatroom_id: uuid!, $date: timestamptz!) {
    update_request_by_pk(
      pk_columns: { id: $input_chatroom_id }
      _set: { user_unread_message_count: 0, update_date: $date }
    ) {
      id
    }
  }
`;
const UPDATE_UNREAD_TECHNICIAN_MESSAGE = gql`
  mutation MyMutation(
    $input_chatroom_id: uuid!
    $unread_message_count: Int!
    $update_date: timestamptz!
  ) {
    update_request_by_pk(
      pk_columns: { id: $input_chatroom_id }
      _set: {
        technician_unread_message_count: $unread_message_count
        update_date: $update_date
      }
    ) {
      id
    }
  }
`;
const SUBSCRIBE_REQUEST_CHATROOM = gql`
  subscription SubscribeRequestChatroom($chatroomId: uuid!) {
    request(where: { id: { _eq: $chatroomId } }) {
      id
      technician_unread_message_count
      user_unread_message_count
    }
  }
`;
const SUBSCRIBE_REQUEST_MESSAGES = gql`
  subscription SubscribeRequestMessages($chatroomId: uuid!) {
    request_message(
      where: { request_chatroom_id: { _eq: $chatroomId } }
      order_by: { published_date: asc }
    ) {
      id
      sender_id
      content
      published_date
      sender_name
      image
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation SendMessage(
    $chatroomId: uuid!
    $senderId: uuid!
    $content: String!
    $senderName: String!
    $image: String
  ) {
    insert_request_message(
      objects: {
        request_chatroom_id: $chatroomId
        sender_id: $senderId
        content: $content
        sender_name: $senderName
        image: $image
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const formatFileName = (file) => {
  const fileNameParts = file.name.split(".");
  const extension = fileNameParts.pop();
  const baseName = fileNameParts.join(".");
  const timestamp = Date.now();
  const newFileName = `${baseName}-${timestamp}.${extension}`;
  return newFileName;
};

const isImage = (path) => {
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"];
  return imageExtensions.some((ext) => path.toLowerCase().endsWith(ext));
};

const Message = (props) => {
  const [chatroom, setChatroom] = useState({ id: 0 });
  const [inputMessage, setInputMessage] = useState("");
  const {
    setIsListRefetching,
    handleRefetchRequestList,
    userId,
    userName,
    setTempMessageFile,
  } = useContext(useGlobalContext);
  const [messageList, setMessageList] = useState([]);

  const [highlightMessageAmount, setHighlightMessageAmount] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const urlCache = React.useRef({});
  const listContainerRef = React.useRef(null);

  const { height, chatroomId } = props;

  const [updateChatroom] = useMutation(UPDATE_CHATROOM);
  const [updateUnreadTechnicianMessage] = useMutation(
    UPDATE_UNREAD_TECHNICIAN_MESSAGE
  );
  const {
    data: messageData,
    loading: messageLoading,
    error,
  } = useSubscription(SUBSCRIBE_REQUEST_MESSAGES, {
    variables: { chatroomId: chatroomId },
    skip: !chatroomId, // Skip subscription if chatroomId is not set
  });
  const { data: subscriptionData } = useSubscription(
    SUBSCRIBE_REQUEST_CHATROOM,
    {
      variables: { chatroomId: chatroomId },
      skip: !chatroomId, // Skip subscription if chatroomId is not set
    }
  );
  const [sendMessage] = useMutation(SEND_MESSAGE);

  if (error) {
    console.log(error.message);
  }

  const scrollToBottom = () => {
    if (listContainerRef.current) {
      listContainerRef.current.scrollTop =
        listContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (subscriptionData) {
      const updatedChatroom = subscriptionData.request[0];
      console.log("updatedChatroom", updatedChatroom);
      setChatroom({
        id: updatedChatroom.id,
        technician_unread_message_count:
          updatedChatroom.technician_unread_message_count,
        status: updatedChatroom.user_unread_message_count,
      });
    }
  }, [subscriptionData]);

  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  // Get the image urls for the messages
  useEffect(() => {
    if (messageData) {
      const updateMessagesWithUrls = async () => {
        const updatedMessages = await Promise.all(
          messageData.request_message.map(async (message) => {
            if (message.image && !urlCache.current[message.image]) {
              const url = await getUrl({
                path: message.image,
                options: {
                  validateObjectExistence: true,
                },
              });
              urlCache.current[message.image] = url;

              return { ...message, image: url };
            }
            return {
              ...message,
              image: urlCache.current[message.image] || message.image,
            };
          })
        );
        setMessageList(updatedMessages);
      };
      updateMessagesWithUrls();
    }
  }, [messageData]);

  useEffect(() => {
    if (chatroom.status && chatroom.status !== 0) {
      setHighlightMessageAmount(chatroom.status);
      const currentDate = new Date().toISOString();
      updateChatroom({
        variables: { input_chatroom_id: chatroom.id, date: currentDate },
      }).then(() => {
        setIsListRefetching(true);
        handleRefetchRequestList();
      });
    }
  }, [chatroom, updateChatroom, setIsListRefetching, handleRefetchRequestList]);

  const {
    token: { colorBgLayout },
  } = theme.useToken();

  const attachmentProps = {
    customRequest: async ({
      file,
      onSuccess,
      onError,
      onProgress: onProgressInternal,
    }) => {
      try {
        const formatedFileName = formatFileName(file);
        await uploadData({
          path: `public/${chatroomId}/messageFile/${formatedFileName}`,
          data: file,
          options: {
            onProgress: async (progressEvent) => {
              const percent = Math.round(
                (progressEvent.transferredBytes / progressEvent.totalBytes) *
                  100
              );
              onProgressInternal({ percent }, file);
              if (percent === 100) {
                file.path = `public/${chatroomId}/messageFile/${formatedFileName}`;
              }
            },
          },
        }).result;
        setTempMessageFile(file.path);
        onSuccess(file);
      } catch (error) {
        file.status = "error";
        onError(error, file);
      }
    },

    maxCount: 1,
    beforeUpload: (file) => {
      if (file.size > 1024 * 1024 * 1024) {
        message.error("File size should be less than 1GB");
        return Upload.LIST_IGNORE;
      }
    },
    onChange(info) {
      const { status, name } = info.file;
      if (status === "uploading") {
        info.file.name = formatFileName(info.file);
        setUploadFile([info.file]);

        if (status === "error") {
          message.error(`${name} file upload failed.`);
        }
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      style: {
        maxWidth: "20vw",
        fontSize: 12,
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onSuccess: (file) => {
      message.success(`${file.name} file uploaded successfully.`);

      setUploadFile([file]);
    },
    onError: (error, file) => {
      message.error(`${file.name} upload failed. Because ${error}`);
      file.status = "error";
      setUploadFile([file]);
    },
    onRemove: (info) => {
      try {
        console.log("Remove ", uploadFile);
        setTempMessageFile(null);
        remove({
          path: uploadFile[0].path,
        });
      } catch (error) {
        console.log("Error ", error);
      }
      setUploadFile([]);
    },
  };
  const renderItem = useMemo(
    () => (item, index) => {
      const isHighlighted =
        index >= messageList.length - highlightMessageAmount;
      const ListItemComponent = isHighlighted
        ? HighlightedListItem
        : NormalListItem;

      return (
        <ListItemComponent key={item.id}>
          <Space direction="vertical">
            <div>
              <strong>{item.sender_name}</strong> -{" "}
              {new Date(item.published_date).toLocaleString("en-NZ")}
            </div>
            <div>{item.content}</div>
            {item.image &&
              (isImage(item.image.url.pathname) ? (
                <Image
                  width={150}
                  src={item.image.url.href}
                  onLoad={scrollToBottom}
                />
              ) : (
                <Button
                  icon={<DownloadOutlined />}
                  href={item.image.url.href}
                  download
                >
                  {item.image.url.pathname.split("/").pop()}
                </Button>
              ))}
          </Space>
        </ListItemComponent>
      );
    },
    [messageList, highlightMessageAmount]
  );

  return (
    <Flex justify="space-between">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          borderLeftWidth: 2,
          borderLeftColor: colorBgLayout,
          borderLeftStyle: "solid",
        }}
      >
        <div>
          <div
            style={{
              width: "100%",
              paddingLeft: 24,
              borderLeftColor: "white",
              borderLeftStyle: "solid",
              height: height - 142,
              overflowY: "auto",
            }}
            ref={listContainerRef}
          >
            <List
              dataSource={messageList}
              renderItem={renderItem}
              loading={messageLoading}
              locale={{
                emptyText: (
                  <div style={{ paddingTop: (height - 250) / 3 }}>
                    <SmileOutlined style={{ fontSize: 60 }} />
                    <div style={{ paddingTop: 30 }}>Chat with us</div>
                  </div>
                ),
              }}
              style={{
                paddingTop: messageLoading ? 0.5 * (height - 32) : 0,
              }}
            />
          </div>
        </div>

        <div
          hidden={chatroomId === null}
          style={{
            backgroundColor: colorBgLayout,
            height: 142,
            alignContent: "center",
            paddingLeft: 24,
          }}
        >
          {/* <Button onClick={() => handleScrollToTen()}>
                  try scroll to 10
                </Button> */}
          <Flex direction="horizontal" style={{ width: "100%" }}>
            <Input.TextArea
              placeholder="Type your message"
              autoSize={{ minRows: 4, maxRows: 5 }}
              style={{ width: "95%" }}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
            />
            <Space
              direction="vertical"
              size={"large"}
              style={{ paddingLeft: 16, paddingRight: 16 }}
            >
              <Upload {...attachmentProps} fileList={uploadFile}>
                <LinkOutlined
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                  }}
                />
              </Upload>
              <Spin
                spinning={isSending}
                indicator={<LoadingOutlined spin />}
                size="small"
              />
              <SendOutlined
                style={{
                  display: isSending ? "none" : "block",
                  fontSize: 16,
                  position: "fixed",
                  bottom: 32,
                }}
                onClick={(e) => {
                  if (inputMessage === "") {
                    message.error("Message can't be empty");
                    return;
                  }

                  updateUnreadTechnicianMessage({
                    variables: {
                      input_chatroom_id: chatroomId,
                      unread_message_count:
                        chatroom.technician_unread_message_count + 1,
                      update_date: new Date().toISOString(),
                    },
                  });

                  setIsSending(true);
                  sendMessage({
                    variables: {
                      chatroomId: chatroomId,
                      senderId: userId,
                      content: inputMessage,
                      senderName: userName,
                      image: uploadFile.length > 0 ? uploadFile[0].path : null,
                    },
                  }).then(() => {
                    setInputMessage("");
                    setIsSending(false);
                    setTempMessageFile(null);
                    setUploadFile([]);
                  });
                }}
              />
            </Space>
          </Flex>
        </div>
      </div>
    </Flex>
  );
};

export default withThemeSwitch(Message);

const highlightFade = keyframes`
  25% {
    background-color: #a5f8e2;
  }
  100% {
    background-color: transparent;
  }
`;
const HighlightedListItem = styled(List.Item)`
  border-bottom: none;
  font-size: 16px;
  margin: 10px 0;
  padding: 8px;
  animation: ${highlightFade} 1.2s forwards;
  animation-iteration-count: 2;
`;

const NormalListItem = styled(List.Item)`
  border-bottom: none;
  font-size: 16px;
  margin: 10px 0;
  padding: 8px;
`;
